import { Component, OnInit } from '@angular/core';
import { CatalogsService } from 'src/app/services/catalogs-services/catalogs.service';
import { tap } from 'rxjs/internal/operators/tap';
import { StorageService } from 'src/app/services/storage-service/storage.service';
import { catchError, take } from 'rxjs/internal/operators';
import { PrintService } from 'src/app/services/print-services/print.service';
import { Router } from '@angular/router';
import { CartItem } from 'src/app/models/cart-item.model';
import { CartService } from 'src/app/services/cart-services/cart.service';
import { Location } from '@angular/common'

@Component({
  selector: 'app-printing',
  templateUrl: './printing.component.html',
  styleUrls: ['./printing.component.scss']
})
export class PrintingComponent implements OnInit {

  loadingConfig;
  center;
  banner;
  properties;
  total;



  constructor(private storageService: StorageService,
              private printService: PrintService,
              private router: Router,
              private cartService: CartService,
              private catalogsService: CatalogsService,
              private location: Location) { }

  ngOnInit() {
    this.center = this.storageService.getCenter();
    this.banner = this.storageService.getMCatalogBanner();
    this.setLoadingAnimation();
    this.process();
    this.total = this.cartService.getCartTotal();

  }

  public process() {
    this.printService.androidPrint(this.storageService.getTicketHTML());
    if (Object.entries(this.getDesks()).length > 0) {
      Object.entries(this.getDesks()).forEach(v => {
        this.catalogsService.getTurn(v[1][0]['catalog']['internalId'], v[0], this.storageService.getOrderId(), this.storageService.getSmsPhone()).pipe(
          take(1),
          tap(o => this.printService.androidPrint(o['turnTicketHtml'])),
          tap(o => this.router.navigate(['/registered/layout/confirmation'])),
          catchError(o =>  this.router.navigate(['/registered/layout/confirmation']))
        ).subscribe();
      });
    } else {
      this.router.navigate(['/registered/layout/confirmation']);
    }
  }

  private getDesks() {
    const desks = [];
    this.cartService.getSavedCart().forEach((i: CartItem) => {
      if (i.Data.Catalog.TurnDesks && i.Data.Catalog.TurnDesks[0]) {
        if (desks[i.Data.Catalog.TurnDesks[0].TurnDeskInternalId]) {
          desks[i.Data.Catalog.TurnDesks[0].TurnDeskInternalId].push(i.Data);
        } else {
          desks[i.Data.Catalog.TurnDesks[0].TurnDeskInternalId] = [i.Data];
        }
      }
    });
    return desks;
  }

  public cancel(): void {
    this.location.back()  
  }

  private setLoadingAnimation() {
    this.loadingConfig = {
      path: 'assets/animations/printing.animation.json',
      renderer: 'canvas',
      autoplay: true,
      loop: true
    };
  }
}
