export class Preparation {

    private code: string;
    private description: string;
    private image: string;
    private internalId: string;

    constructor(code?: string, description?: string, image?: string, internalId?: string) {
        this.code = code;
        this.description = description;
        this.image = image;
        this.internalId = internalId;
    }

    public get Code() {
        return this.code;
    }

    public set Code(code) {
        this.code = code;
    }

    public get Description() {
        return this.description;
    }

    public set Description(description) {
        this.description = description;
    }

    public get Image() {
        return this.image;
    }

    public set Image(image) {
        this.image = image;
    }

    public get InternalId() {
        return this.internalId;
    }

    public set InternalId(internalId) {
        this.internalId = internalId;
    }
}
