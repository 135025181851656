import { Component, OnInit, HostListener } from "@angular/core";
import { BehaviorSubject, Observable, Subscription, of } from "rxjs";
import {
  debounceTime,
  distinctUntilChanged,
  map,
  take,
  tap,
} from "rxjs/operators";
import { CatalogsService } from "src/app/services/catalogs-services/catalogs.service";
import { Location } from "@angular/common";
import { ProductDialogComponent } from "../product-dialog/product-dialog.component";
import { DialogService } from "../dialog/dialog.service";

@Component({
  selector: "app-search",
  templateUrl: "./search.component.html",
  styleUrls: ["./search.component.scss"],
})
export class SearchComponent implements OnInit {
  @HostListener('scroll', ['$event']) onScroll(event: Event): void {
  const target = event.target as HTMLElement;

  if (target.scrollTop === target.scrollHeight - target.clientHeight) {
    this.lengthLoad = this.lengthLoad + 14;
    console.log(this.lengthLoad);
  }
}

  searchTerm = new BehaviorSubject<string>("");
  catalogs;
  products;
  catalog;
  private listProducts = [];
  listFiltered: Observable<string[]>;
  loadingAnimationConfig;
  loading;
  lengthLoad;

  constructor(
    private location: Location,
    private catalogsService: CatalogsService,
    private dialogService: DialogService
  ) {}

  ngOnInit(): void {
    this.setLoadingAnimationConfig();
    this.loading = true;
    this.lengthLoad = 14;

    this.catalogsService
      .getCatalogs()
      .pipe(
        take(1),
        tap((catalogs) => (this.catalogs = catalogs)),
        tap((catalogs) =>
          catalogs.forEach((i, index) =>
            this.catalogsService
              .getCatalog(catalogs[index].InternalId)
              .pipe(
                take(1),
                tap((catalog) => (this.catalog = catalog)),
                tap((catalog) =>
                  catalog.Products.forEach((i) => this.listProducts.push(i))
                )
              )
              .subscribe()
          )
        ),
        tap((catalog) => console.log(this.listProducts)),
        tap((catalogs) => this.filterList()),
        tap((o) => (this.loading = false))
      )
      .subscribe();
  }

  public openProductModal(product) {
    const dialog = this.dialogService.open(ProductDialogComponent, {
      data: { product, catalog: this.catalog },
      modal: true,
    });
    dialog.afterClosed.pipe().subscribe();
  }

  filterList(): void {
    this.listFiltered = this.searchTerm.pipe(
      debounceTime(400),
      distinctUntilChanged(),
      map((term) => {
        return this.listProducts.filter(
          (item) =>
            item.Description.toLowerCase().indexOf(term.toLowerCase()) >= 0
        );
      })
    );
  }

  private setLoadingAnimationConfig() {
    this.loadingAnimationConfig = {
      path: "assets/animations/loading.animation.json",
      renderer: "canvas",
      autoplay: true,
      loop: true,
    };
  }

  public cancel(): void {
    this.location.back();
  }
}
