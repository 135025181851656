import { Component, OnInit, HostListener } from '@angular/core';
import { DialogRef } from '../dialog/dialog-ref';
import { InactivityService } from 'src/app/services/inactivity-service/inactivity.service';
import { StorageService } from 'src/app/services/storage-service/storage.service';

@Component({
  selector: 'app-delete-product-dialog',
  templateUrl: './delete-product-dialog.component.html',
  styleUrls: ['./delete-product-dialog.component.scss']
})
export class DeleteProductDialogComponent implements OnInit {

  primaryColor;

  constructor(private dialog: DialogRef,
              private storageService: StorageService,
              private inactivityService: InactivityService) { }

  ngOnInit() {
    this.primaryColor = this.storageService.getMCatalogPrimaryColor();
  }

  @HostListener('click', ['$event'])
  public click(e) {
    // this.inactivityService.reloadInactivityTimer();
  }

  public cancel() {
    this.dialog.close();
  }

  public delete() {
    this.dialog.close(true);
  }
}
