import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterByCategory',
  pure: false
})
export class FilterByCategoryPipe implements PipeTransform {

  transform(values: Array<any>, ...args: any[]): any {
    if (values && args[0]) {
      return values.filter(v => v['Category'] === args[0]);
    }
    return values;
  }

}
