import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-type-card',
  templateUrl: './type-card.component.html',
  styleUrls: ['./type-card.component.scss']
})
export class TypeCardComponent implements OnInit {

  @Input() service;
  @Input() active;
  @Input() i;

  constructor() { }

  ngOnInit() {
  }

  public getServiceThumbnail(service) {
    switch (service) {
      case 'EATIN':
        return 'assets/icons/cutlery.svg';
      case 'DELIVERY':
        return 'assets/icons/truck.svg';
      case 'TAKEAWAY':
        return 'assets/icons/eatbag.svg';
      case 'NOW':
        return 'assets/icons/bag.svg';
    }
  }

}
