export class Price {

    private significand: number;
    private exponent: number;
    private currency: string;

    constructor(significand?: number, exponent?: number, currency?: string) {
        this.significand = significand;
        this.exponent = exponent;
        this.currency = currency;
    }

    public get Significand() {
        return this.significand;
    }

    public set Significand(significand) {
        this.significand = significand;
    }

    public get Exponent() {
        return this.exponent;
    }

    public set Exponent(exponent) {
        this.exponent = exponent;
    }

    public get Currency() {
        return this.currency;
    }

    public set Currency(currency) {
        this.currency = currency;
    }
}
