import { Injectable } from '@angular/core';
import { InactivityDialogComponent } from 'src/app/components/inactivity-dialog/inactivity-dialog.component';
import { InactivityService } from '../inactivity-service/inactivity.service';
declare const Android;

@Injectable({
  providedIn: 'root'
})
export class MhelpService {

  constructor(private inactivityService: InactivityService) { }

  public openmHelp() {
    this.inactivityService.cancelInactivityTimer();
    try {
      // tslint:disable-next-line:only-arrow-functions
      (function() { Android.help(); })();
    } catch (e) {
      console.dir(e);
    }
  }

}
