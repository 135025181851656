import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { StorageService } from "src/app/services/storage-service/storage.service";
import { CartService } from "src/app/services/cart-services/cart.service";
import { Catalog } from "src/app/models/catalog.model";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-welcome",
  templateUrl: "./welcome.component.html",
  styleUrls: ["./welcome.component.scss"],
})
export class WelcomeComponent implements OnInit {
  center;
  constructor(
    private storageService: StorageService,
    private cartService: CartService,
    private router: Router,
    private translateService: TranslateService,

  ) {}

  ngOnInit() {
    this.storageService.deleteTemporalItems();
    this.cartService.emptyCart();
    this.center = this.storageService.getCenter();
    // this.setLang(this.center.defaultLanguage.locale);

  }

  // public setLang(lang) {
  //   this.translateService.use(lang);
  //   this.storageService.setLang(lang);
  // }

  public gotoCatalogs() {
    this.router.navigate(["/registered/layout/catalogs", "catalogs"]);

  }

}
