import { Injectable } from '@angular/core';
import { PricePipe } from 'src/app/pipes/price.pipe';
import { Price } from 'src/app/models/price.model';
import { StorageService } from '../storage-service/storage.service';
import { TranslateService } from '@ngx-translate/core';

declare const Android;

@Injectable({
  providedIn: 'root'
})
export class PrintService {

  constructor(private pricePipe: PricePipe,
              private translateService: TranslateService,
              private storageService: StorageService) { }

  public getTicketHtml(products) {
    let html = `<table style="width:100%;border:0;">`;
    html += `<tr><td style="font-size:26px;font-weight:500;text-align:right;" colspan="4">${this.translateService.instant(this.storageService.getType())}</td></tr>`;
    products.forEach(p => {
      html += `
      <tr>
        <td style="font-size:35px;font-weight:600;">${p.Product.Description}</td>
        <td style="font-size:30px;font-weight:500;">${p.Qty}${p.Product.MeasureUnit}</td>
        <td style="font-size:30px;font-weight:500;text-align:right;text-decoration:line-through;">${ p.Product.PricePVPOffer.Significand !== 0 ? this.pricePipe.transform(this.getSubtotal(p.product.PricePVP, p.Product.Measure, p.Qty )) : '' }</td>
        <td style="font-size:30px;font-weight:500;text-align:right;">${ p.Product.PricePVPOffer.Significand !== 0 ? this.pricePipe.transform(this.getSubtotal(p.Product.PricePVPOffer, p.Product.Measure, p.qty)) : this.pricePipe.transform(this.getSubtotal(p.Product.PricePVP, p.Product.Measure, p.Qty )) }</td>
      </tr>
      <tr>
        <td style="font-size:30px;font-weight:400;padding-left: 10px;">${p.Preparation ? '[' + p.Preparation.Description + ']' : '-'}</td>
        <td style="font-size:30px;font-weight:600;"></td>
        <td style="font-size:30px;font-weight:600;"></td>
        <td style="font-size:30px;font-weight:600;"></td>
      </tr>`;
    });
    html += `\n</table>`;
    html += `\n<p style="font-size:40px;font-weight:800;width:100%;text-align:right;">Total: <span style="font-size:40px;font-weight:800;margin-left:10px;">${this.getCartTotal(products)}€</span></p>`;
    return html;
  }

  public getCartTotal(products) {
    let total = 0;
    total = products.reduce((previous, current) => {
      if (current.Product.PricePVPOffer.Significand !== 0) {
        return previous + (current.Qty / current.Product.Measure * parseFloat((current.Product.PricePVPOffer.Significand * Math.pow(10, current.Product.PricePVPOffer.Exponent)).toFixed(2)));
      }
      return previous + (current.Qty / current.Product.Measure * parseFloat((current.Product.PricePVP.Significand * Math.pow(10, current.Product.PricePVP.Exponent)).toFixed(2)));
    }, 0);

    return total.toFixed(2).replace(/[\.]/g, ',');
  }

  public androidPrint(html) {
    try {
      // tslint:disable-next-line:only-arrow-functions
      (function() { Android.printHTML(html); })();
    } catch (e) {
      console.dir(e);
    }
  }

  public getSubtotal(price, measure, qty) {
    const p = new Price();
    Object.assign(p, {
      significand: price.Significand,
      exponent: price.Exponent,
      currency: price.Currency
    });
    p.Significand = p.Significand / measure * qty;
    return p;
  }
}
