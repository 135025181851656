import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OrderDaoService {

  private BASE_URL = environment.apiURL;
  private OPTIONS = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json; charset=UTF-8'
    })
  };

  constructor(private http: HttpClient) { }

  public confirmOrder(context , lang, app, paymentType, serviceType, bankTransaction, customerPhone, customerName, customerEmail, articles, totalAmount) {
    const options = {};
    Object.assign(options, this.OPTIONS);
    options['headers'] = options['headers'].append('context', context); 
    options['headers'] = options['headers'].append('lang', lang);
    const item = { app, paymentType, serviceType, bankTransaction, customerName, customerPhone, customerEmail, articles, totalAmount };
    return this.http.post(this.BASE_URL + 'api/v2.0/orders', item, options);
  }

  public getOrderStatus(context , lang, internalId) {
    const options = {};
    Object.assign(options, this.OPTIONS);
    options['headers'] = options['headers'].append('context', context); 
    options['headers'] = options['headers'].append('lang', lang);
    options['params'] = { internalId };
    return this.http.get(this.BASE_URL + 'api/v2.0/orders/status', options);
  }
}
