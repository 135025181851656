import { Catalog } from './catalog.model';
import { Preparation } from './preparation.model';
import { Product } from './product.model';

export enum CartItemOrigin {
    MANUAL = 'MANUAL',
    SCANNER = 'SCANNER',
    RFID = 'RFID'
  }

export class CartItem {

    private id: number;
    private origin: CartItemOrigin;
    private data: CartItemData;

    constructor(id?: number, data?: CartItemData, origin?: CartItemOrigin) {
        this.id = id;
        this.data = data;
        this.origin = origin;
    }

    public get Id(): number {
        return this.id;
    }
    public set Id(id: number) {
        this.id = id;
    }
    public get Origin(): CartItemOrigin {
        return this.origin;
    }
    public set Origin(origin: CartItemOrigin) {
        this.origin = origin;
    }
    public get Data(): CartItemData {
        return this.data;
    }
    public set Data(data: CartItemData) {
        this.data = data;
    }
}

export class CartItemData {
    private catalog: Catalog;
    private preparation: Preparation | null;
    private product: Product;
    private qty: number;

    constructor(catalog?: Catalog, preparation?: Preparation | null, product?: Product, qty?: number) {
        this.catalog = catalog;
        this.preparation = preparation;
        this.product = product;
        this.qty = qty;
    }

    public get Catalog(): Catalog {
        return this.catalog;
    }
    public set Catalog(catalog: Catalog) {
        this.catalog = catalog;
    }
    public get Preparation(): Preparation | null {
        return this.preparation;
    }
    public set Preparation(preparation: Preparation | null) {
        this.preparation = preparation;
    }
    public get Product(): Product {
        return this.product;
    }
    public set Product(product: Product) {
        this.product = product;
    }
    public get Qty(): number {
        return this.qty;
    }
    public set Qty(qty: number) {
        this.qty = qty;
    }

}
