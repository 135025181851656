import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-add-preparation',
  templateUrl: './add-preparation.component.html',
  styleUrls: ['./add-preparation.component.scss']
})
export class AddPreparationComponent implements OnInit {

  @Input() preparations;
  @Output() selectEmitter = new EventEmitter<any>();
  selected;

  constructor() { }

  ngOnInit() {
  }


  public selectPreparation(preparation) {
    this.selected = preparation;
    this.selectEmitter.emit(preparation);
  }
}
