import { Injectable, isDevMode } from '@angular/core';
import { ReactiveSocket } from 'sockets-lib';
import { MaintenanceService } from '../maintenance-service/maintenance.service';
import { StorageService } from '../storage-service/storage.service';
import { CommunicationDaoService } from 'src/app/dao/communication-dao-services/communication-dao.service';
import { map } from 'rxjs/internal/operators/map';

@Injectable({
  providedIn: 'root'
})
export class CommunicationService {

  nSocket: ReactiveSocket;

  constructor(private maintenanceService: MaintenanceService,
              private storageService: StorageService,
              private communicationDaoService: CommunicationDaoService) { }


}
