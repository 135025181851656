import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { StorageService } from 'src/app/services/storage-service/storage.service';

@Component({
  selector: 'app-add-qty',
  templateUrl: './add-qty.component.html',
  styleUrls: ['./add-qty.component.scss']
})
export class AddQtyComponent implements OnInit {

  @Input() measure;
  @Input() measureUnit;
  @Output() selectEmitter = new EventEmitter<any>();
  qty = 0;
  primaryColor;

  constructor(private storageService: StorageService) {
    this.primaryColor = this.storageService.getMCatalogPrimaryColor();
  }

  ngOnInit() {
    this.initQty();
  }

  public minusQty() {
    switch (this.measureUnit) {
      case 'ud':
        this.qty - 1 > 0 ? this.qty -= 1 : null;
        break;
      case 'qty':
        this.qty - 1 > 0 ? this.qty -= 1 : null;
        break;
      case 'gr':
        this.qty - 100 > 0 ? this.qty -= 100 : null;
        break;
      case 'kg':
        this.qty - 1 > 0 ? this.qty -= 1 : null;
        break;
      case 'ml':
        this.qty - 100 > 0 ? this.qty -= 100 : null;
        break;
      case 'mm':
        this.qty - 100 > 0 ? this.qty -= 100 : null;
        break;
      case 'inch':
        this.qty - 1 > 0 ? this.qty -= 1 : null;
        break;
      case 'oz':
        this.qty - 1 > 0 ? this.qty -= 1 : null;
        break;
    }
    this.selectEmitter.emit(this.qty);
  }

  public plusQty() {
    switch (this.measureUnit) {
      case 'ud':
        this.qty += 1;
        break;
      case 'qty':
        this.qty += 1;
        break;
      case 'gr':
        this.qty += 100;
        break;
      case 'kg':
        this.qty += 1;
        break;
      case 'ml':
        this.qty += 100;
        break;
      case 'mm':
        this.qty += 100;
        break;
      case 'inch':
        this.qty += 1;
        break;
      case 'oz':
        this.qty += 1;
        break;
    }
    this.selectEmitter.emit(this.qty);
  }

  public initQty() {
    switch (this.measureUnit) {
      case 'ud':
        this.qty = 1;
        break;
      case 'qty':
        this.qty = 1;
        break;
      case 'gr':
        this.qty = 100;
        break;
      case 'kg':
        this.qty = 1;
        break;
      case 'ml':
        this.qty = 100;
        break;
      case 'mm':
        this.qty = 100;
        break;
      case 'inch':
        this.qty = 1;
        break;
      case 'oz':
        this.qty = 1;
        break;
    }
    this.selectEmitter.emit(this.qty);
  }
}
