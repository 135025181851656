import { Component, OnInit, NgZone, HostListener } from "@angular/core";
import { StorageService } from "./services/storage-service/storage.service";
import { switchMap, map, tap, catchError, take } from "rxjs/internal/operators";
import { OauthTokenData } from "oauth-lib";
import { empty } from "rxjs";
import { MaintenanceService } from "./services/maintenance-service/maintenance.service";
import { FingerprintService } from "./services/fingerprint-services/fingerprint.service";
import { TranslateService } from "@ngx-translate/core";
import { SecurityService } from "./services/security-service/security.service";
import { Router, ActivatedRoute } from "@angular/router";
import { CatalogsService } from "./services/catalogs-services/catalogs.service";
import { DialogService } from "./components/dialog/dialog.service";
import { CartService } from "./services/cart-services/cart.service";
import { ScannedDialogComponent } from "./components/scanned-dialog/scanned-dialog.component";
import { CartItemData, CartItemOrigin } from "./models/cart-item.model";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  token: string;
  constructor(
    private storageService: StorageService,
    private maintenanceService: MaintenanceService,
    private fingerPrintService: FingerprintService,
    private translateService: TranslateService,
    private securityService: SecurityService,
    private router: Router,
    private catalogsService: CatalogsService,
    private dialogService: DialogService,
    private cartService: CartService,
    private ngZone: NgZone,
    private activatedRoute: ActivatedRoute,

  ) {
  }

 
  @HostListener("window:message", ["$event"]) onPostMessage(event) {
    let data = null;
    try {
      if (event["data"]) {
        data = JSON.parse(event["data"]);
        if (data && data["deviceDetails"]) {
          this.setDeviceInfo(data["deviceDetails"], true);
          this.securityService
            .requestOAuthToken()
            .pipe(
              take(1),
              map((token: OauthTokenData) =>
                this.storageService.setOAuthToken("token.access_token")
              ),
              switchMap((saved) => this.maintenanceService.isRegistered()),
              map((isRegistered) => this.redirect(isRegistered)),
              catchError((error) => {
                console.dir(error);
                return empty();
              })
            )
            .subscribe();
        } else if (data && data["scannedCode"]) {
          const urls = [
            "/registered/welcome",
            "/registered/layout/catalogs",
            "/registered/layout/order",
          ];
          if (urls.find((u) => this.router.url === u)) {
            if (this.router.url === "/registered/welcome") {
              this.storageService.setUrlContext(this.activatedRoute.snapshot.paramMap.get('urlContext'));

              this.router.navigate(["/registered/layout/catalogs", "catalogs"]);
            }
            this.catalogsService
              .getProductByGTIN(data["scannedCode"])
              .pipe(
                tap((o) => {
                  if (o) {
                    this.cartService.addItem(
                      Date.now(),
                      new CartItemData(o.Catalog, null, o, 1),
                      CartItemOrigin.SCANNER
                    );
                    this.dialogService.closeAll();
                    this.dialogService.open(ScannedDialogComponent, {
                      data: { product: o },
                      modal: true,
                    });
                  } else {
                    this.dialogService.closeAll();
                    this.dialogService.open(ScannedDialogComponent, {
                      data: null,
                      modal: true,
                    });
                  }
                })
              )
              .subscribe();
          }
        } else if (data && data["rfidCode"]) {
          const urls = [
            "/registered/layout/catalogs",
            "/registered/layout/order",
          ];
          if (urls.find((u) => this.router.url === u)) {
            this.cartService.deleteItemsByOrigin(CartItemOrigin.RFID);
            if (data["rfidCode"] && data["rfidCode"].length > 0) {
              this.catalogsService
                .getProductsByGTIN(data["rfidCode"])
                .pipe(
                  tap((o) => {
                    if (o && o.length > 0) {
                      o.forEach((p) =>
                        this.cartService.addItem(
                          Date.now(),
                          new CartItemData(p.Catalog, null, p, 1),
                          CartItemOrigin.RFID
                        )
                      );
                      this.router.navigate(["/registered/layout/order"]);
                    }
                  })
                )
                .subscribe();
            }
          }
        }
      }
    } catch (e) {
      console.dir(e);
    }
  }

  ngOnInit() {
    this.storageService.setUrlContext(this.activatedRoute.snapshot.paramMap.get('urlContext'));
    this.storageService.setLang(this.fingerPrintService.getLanguage());
    console.log(this.storageService.getLang())

    if (!this.fingerPrintService.getAndroidFingerprint()) {
      this.storageService.setUrlContext(this.activatedRoute.snapshot.paramMap.get('urlContext'));

      this.genericFingerprint();
    }
  }

  private genericFingerprint() {
    this.fingerPrintService
      .getFingerPrint()
      .pipe(
        take(1),
        tap((fingerprint) => this.setDeviceInfo(fingerprint, false)),
        switchMap((fingerprint) => this.securityService.requestOAuthToken()),
        map((token: OauthTokenData) =>
          this.storageService.setOAuthToken(token.access_token)
        ),
        switchMap((saved) => this.maintenanceService.isRegistered()),
        map((isRegistered) => this.redirect(isRegistered)),
        catchError((error) => {
          console.dir(error);
          return empty();
        })
      )
      .subscribe();
  }

  private setDeviceInfo(fingerprint, isRealDeviceFingerprint) {
    this.translateService.setDefaultLang("en_GB");
    this.storageService.setLang(this.fingerPrintService.getLanguage());
    this.translateService.use(this.storageService.getLang());
    this.storageService.setDeviceData(
      this.maintenanceService.fingerPrintDevice(
        fingerprint,
        isRealDeviceFingerprint
      )
    );
      }
      
  private redirect(isRegistered) {
    this.storageService.setUrlContext(this.activatedRoute.snapshot.paramMap.get('urlContext'));

      this.ngZone.run(() => {
        if (this.router.url.includes("/registered")) {
          this.router.navigate([this.router.url]);
        } else {
          this.router.navigate(["registered/welcome"]);
        }
      });
  }
}
