import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class InactivityService {

  inactivityTimerSubscription: Subscription;

  constructor() { }

  public cancelInactivityTimer() {
    if (this.inactivityTimerSubscription) { this.inactivityTimerSubscription.unsubscribe(); }
  }

}
