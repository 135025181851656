import { Injectable, isDevMode } from '@angular/core';
import { CompanyDaoService } from 'src/app/dao/company-dao-services/company-dao.service';
import { StorageService } from '../storage-service/storage.service';
import { tap } from 'rxjs/internal/operators/tap';
import { map } from 'rxjs/internal/operators/map';
import { Service } from 'src/app/models/service.model';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  constructor(private companyDaoService: CompanyDaoService,
              private storageService: StorageService) { }

  public getCenter() {
    return this.companyDaoService.getCenter(this.storageService.getContext(), this.storageService.getLang()).pipe(
      tap(o => !isDevMode() || console.dir(o)),
      map(o => o['data'])
    );
  }

  public getLegalTerms() {
    return this.companyDaoService.getLegalTerms(this.storageService.getContext(), this.storageService.getLang()).pipe(
      tap(res => !isDevMode() || console.dir(res)),
      map(res => res['data']['legalTermsList'])
    );
  }

  public getMCatalogConfig() {
    return this.companyDaoService.getMCatalogConfig(this.storageService.getContext(), this.storageService.getLang()).pipe(
      tap(res => !isDevMode() || console.dir(res)),
      map(res => res['data']['config'])
    );
  }

  public getMCatalogServices() {
    return this.companyDaoService.getMCatalogServices(this.storageService.getContext(), this.storageService.getLang()).pipe(
      tap(res => !isDevMode() || console.dir(res)),
      map(res => {
        const services = new Array<Service>();
        res['data']['services'].forEach(s => {
          const service = new Service();
          Object.assign(service, s);
          services.push(service);
        });
        return services;
      })
    );
  }
}
