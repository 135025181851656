export class Device {

    private application: string;
    private appVersion: string;
    private battery: string;
    private brand: string;
    private brightness: number;
    private connectionDevice: string;
    private description: string;
    private deviceName: string;
    private deviceType: string;
    private ipAddress: string;
    private locked: boolean;
    private mac: string;
    private major: number;
    private minor: number;
    private model: string;
    private note: string;
    private notifyBackOffice: boolean;
    private osVersion: string;
    private paperOut: boolean;
    private platform: string;
    private pushToken: string;
    private serialNumber: string;
    private signal: number;
    private volume: number;
    private wifiSsid: string;

    constructor(application?: string,
                appVersion?: string,
                battery?: string,
                brand?: string,
                brightness?: number,
                connectionDevice?: string,
                description?: string,
                deviceName?: string,
                deviceType?: string,
                ipAddress?: string,
                locked?: boolean,
                mac?: string,
                major?: number,
                minor?: number,
                model?: string,
                note?: string,
                notifyBackOffice?: boolean,
                osVersion?: string,
                paperOut?: boolean,
                platform?: string,
                pushToken?: string,
                serialNumber?: string,
                signal?: number,
                volume?: number,
                wifiSsid?: string) {

    }

    public get Application() {
        return this.application;
    }
    public set Application(application) {
        this.application = application;
    }
    public get AppVersion() {
        return this.appVersion;
    }
    public set AppVersion(appVersion) {
        this.appVersion = appVersion;
    }
    public get Battery() {
        return this.battery;
    }
    public set Battery(battery) {
        this.battery = battery;
    }
    public get Brand() {
        return this.brand;
    }
    public set Brand(brand) {
        this.brand = brand;
    }
    public get Brightness() {
        return this.brightness;
    }
    public set Brightness(brightness) {
        this.brightness = brightness;
    }
    public get ConnectionDevice() {
        return this.connectionDevice;
    }
    public set ConnectionDevice(connectionDevice) {
        this.connectionDevice = connectionDevice;
    }
    public get Description() {
        return this.description;
    }
    public set Description(description) {
        this.description = description;
    }
    public get DeviceName() {
        return this.deviceName;
    }
    public set DeviceName(deviceName) {
        this.deviceName = deviceName;
    }
    public get DeviceType() {
        return this.deviceType;
    }
    public set DeviceType(deviceType) {
        this.deviceType = deviceType;
    }
    public get IpAddress() {
        return this.ipAddress;
    }
    public set IpAddress(ipAddress) {
        this.ipAddress = ipAddress;
    }
    public get Locked() {
        return this.locked;
    }
    public set Locked(locked) {
        this.locked = locked;
    }
    public get Mac() {
        return this.mac;
    }
    public set Mac(mac) {
        this.mac = mac;
    }
    public get Major() {
        return this.major;
    }
    public set Major(major) {
        this.major = major;
    }
    public get Minor() {
        return this.minor;
    }
    public set Minor(minor) {
        this.minor = minor;
    }
    public get Model() {
        return this.model;
    }
    public set Model(model) {
        this.model = model;
    }
    public get Note() {
        return this.note;
    }
    public set Note(note) {
        this.note = note;
    }
    public get NotifyBackOffice() {
        return this.notifyBackOffice;
    }
    public set NotifyBackOffice(notifyBackOffice) {
        this.notifyBackOffice = notifyBackOffice;
    }
    public get OsVersion() {
        return this.osVersion;
    }
    public set OsVersion(osVersion) {
        this.osVersion = osVersion;
    }
    public get PaperOut() {
        return this.paperOut;
    }
    public set PaperOut(paperOut) {
        this.paperOut = paperOut;
    }
    public get Platform() {
        return this.platform;
    }
    public set Platform(platform) {
        this.platform = platform;
    }
    public get PushToken() {
        return this.pushToken;
    }
    public set PushToken(pushToken) {
        this.pushToken = pushToken;
    }
    public get SerialNumber() {
        return this.serialNumber;
    }
    public set SerialNumber(serialNumber) {
        this.serialNumber = serialNumber;
    }
    public get Signal() {
        return this.signal;
    }
    public set Signal(signal) {
        this.signal = signal;
    }
    public get Volume() {
        return this.volume;
    }
    public set Volume(volume) {
        this.volume = volume;
    }
    public get WifiSsid() {
        return this.wifiSsid;
    }
    public set WifiSsid(wifiSsid) {
        this.wifiSsid = wifiSsid;
    }
}
