import { Injectable, ɵConsole } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CatalogsDaoService {

  private BASE_URL = environment.apiURL;
  private OPTIONS = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json; charset=UTF-8'
    })
  };

  constructor(private http: HttpClient) { }

  public getCatalogs(context, lang) {
    const options = {};
    Object.assign(options, this.OPTIONS);
    options['headers'] = options['headers'].append('context', context);
    options['headers'] = options['headers'].append('lang', lang);
    return this.http.get(this.BASE_URL + 'api/v2.0/catalog/all', options);
  }

  public getCatalog(context, lang, internalId) {
    const options = {};
    Object.assign(options, this.OPTIONS);
    options['headers'] = options['headers'].append('context', context);
    options['headers'] = options['headers'].append('lang', lang);
    options['params'] = { internalId };
    return this.http.get(this.BASE_URL + 'api/v2.0/catalog', options);
  }

  public getTurn(context, lang, catalogInternalId, turnDeskInternalId, orderInternalId, smsNumber) {
    const options = {};
    Object.assign(options, this.OPTIONS);
    options['headers'] = options['headers'].append('context', context);
    options['headers'] = options['headers'].append('lang', lang);
    const item = { catalogInternalId, turnDeskInternalId, orderInternalId, smsNumber };
    return this.http.post(this.BASE_URL + 'api/v2.0/catalog/turn', item, options);
  }

  public getProductsByGTIN(context, lang, gtins) {
    const options = {};
    Object.assign(options, this.OPTIONS);
    options['headers'] = options['headers'].append('context', context);
    options['headers'] = options['headers'].append('lang', lang);
    options['params'] = { gtins };
    const item = { gtins };
    return this.http.get(this.BASE_URL + 'api/v2.0/catalog/products', options);
  }
}

