import { Injectable } from '@angular/core';
import { SecurityService } from '../security-service/security.service';
import { Device } from 'src/app/models/device.model';
import { CartItem } from 'src/app/models/cart-item.model';
import { Payment } from 'src/app/models/payment.model';


@Injectable({
  providedIn: 'root'
})
export class StorageService { 
  private encrypt = false;
  private MCATALOG_LANG_ID = 'mc_la';
  private MCATALOG_CONTEXT_ID = 'mc_cx';
  private MCATALOG_DEVICEDATA_ID = 'mc_cd';
  private MCATALOG_OAUTH_TOKEN = 'mc_oa';
  private MCATALOG_CENTER = 'mc_ce';
  private MCATALOG_REGISTER_TOKEN = 'mc_rt';
  private MCATALOG_CART = 'mc_ca';
  private MCATALOG_PAYMENTS = 'mc_py';
  private MCATALOG_ORDER_ID = 'mc_oi';
  private MCATALOG_SMS_PHONE = 'mc_sp';
  private MCATALOG_TYPE = 'mc_ty';
  private MCATALOG_PAYMENT = 'mc_pa';
  private MCATALOG_BANK_TRANSACTION = 'mc_bt';
  private MCATALOG_BANNER = 'mc_ba';
  private MCATALOG_PRIMARYCOLOR = 'mc_pc';
  private MCATALOG_SECONDARYCOLOR = 'mc_sc';
  private MCATALOG_INACTIVITYTIME = 'mc_it';
  private MCATALOG_TICKET_HTML = 'mc_th';
  private MCATALOG_ASSISTANCEACTIVE = 'mc_aa';
  private MCATALOG_CUSTOMER_NAME = 'mc_cn';
  private MCATALOG_CUSTOMER_EMAIL = 'mc_cm';
  private MCATALOG_CUSTOMER_PHONE = 'mc_cp';


  constructor(private securityService: SecurityService,
    ) { }

    public setUrlContext(urlContext) {
      if (urlContext) {
        try {
          sessionStorage.setItem(this.MCATALOG_CONTEXT_ID, urlContext);
          return true;
        } catch (e) {
          console.log('Fail saving urlContext token in browser. ' + e);
          return false;
        }
      } else {
        return false;
      }
    }
  
    public getUrlContext() {
      if (sessionStorage.getItem(this.MCATALOG_CONTEXT_ID)) {
        try {
          let urlContext = null;
          urlContext = sessionStorage.getItem(this.MCATALOG_CONTEXT_ID);
          console.log("CONTEXT->"+urlContext)
          return urlContext;
        } catch (e) {
          console.log('Fail retrieving context token from browser. ' + e);
          return null;
        }
      } else {
        return null;
      }
    }

  public setContext(context) {
    if (context) {
      try {
        sessionStorage.setItem(this.MCATALOG_CONTEXT_ID, this.getUrlContext());
        return true;
      } catch (e) {
        console.log('Fail saving context token in browser. ' + e);
        return false;
      }
    } else {
      return false;
    }
  }

  public getContext() {
    if (sessionStorage.getItem(this.MCATALOG_CONTEXT_ID)) {
      try {
        let context = null;
        context = sessionStorage.getItem(this.MCATALOG_CONTEXT_ID);
        console.log("CONTEXT->"+context)
        return context;
      } catch (e) {
        console.log('Fail retrieving context token from browser. ' + e);
        return null;
      }
    } else {
      return null;
    }
  }

  public setRegisterToken(token) {
    if (token) {
      try {
        sessionStorage.setItem(this.MCATALOG_REGISTER_TOKEN, this.encrypt ? this.securityService.encrypt(token) : token);
        return true;
      } catch (e) {
        console.log('Fail saving register token in browser. ' + e);
        return false;
      }
    } else {
      return false;
    }
  }

  public getRegisterToken() {
    if (sessionStorage.getItem(this.MCATALOG_REGISTER_TOKEN)) {
      try {
        let registerToken = null;
        this.encrypt ? registerToken = this.securityService.decrypt(sessionStorage.getItem(this.MCATALOG_REGISTER_TOKEN)) : registerToken = sessionStorage.getItem(this.MCATALOG_REGISTER_TOKEN);
        return registerToken;
      } catch (e) {
        console.log('Fail retrieving register token from browser. ' + e);
        return null;
      }
    } else {
      return null;
    }
  }

  public setLang(lang) {
    if (lang) {
      try {
        sessionStorage.setItem(this.MCATALOG_LANG_ID, this.encrypt ? this.securityService.encrypt(lang) : lang);
        return true;
      } catch (e) {
        console.log('Fail saving lang token in browser. ' + e);
        return false;
      }
    } else {
      return false;
    }
  }

  public getLang() {
    if (sessionStorage.getItem(this.MCATALOG_LANG_ID)) {
      try {
        let context = null;
        this.encrypt ? context = this.securityService.decrypt(sessionStorage.getItem(this.MCATALOG_LANG_ID)) : context = sessionStorage.getItem(this.MCATALOG_LANG_ID);
        return context;
      } catch (e) {
        console.log('Fail retrieving lang token from browser. ' + e);
        return null;
      }
    } else {
      return null;
    }
  }

  public setDeviceData(deviceData) {
    if (deviceData) {
      try {
        sessionStorage.setItem(this.MCATALOG_DEVICEDATA_ID, this.encrypt ? this.securityService.encrypt(JSON.stringify(deviceData)) : JSON.stringify(deviceData));
        return true;
      } catch (e) {
        console.log('Fail saving device data in browser. ' + e);
        return false;
      }
    } else {
      return false;
    }
  }

  public getDeviceData() {
    if (sessionStorage.getItem(this.MCATALOG_DEVICEDATA_ID)) {
      try {
        const deviceData = new Device();
        if (this.encrypt) {
          Object.assign(deviceData, JSON.parse(this.securityService.decrypt(sessionStorage.getItem(this.MCATALOG_DEVICEDATA_ID))));
        } else {
          Object.assign(deviceData, JSON.parse(sessionStorage.getItem(this.MCATALOG_DEVICEDATA_ID)));
        }
        return deviceData;
      } catch (e) {
        console.log('Fail retrieving device data from browser. ' + e);
        return null;
      }
    } else {
      return null;
    }
  }

  public setOAuthToken(oAuthToken) {
    if (oAuthToken) {
      try {
        sessionStorage.setItem(this.MCATALOG_OAUTH_TOKEN, this.encrypt ? this.securityService.encrypt(JSON.stringify(oAuthToken)) : JSON.stringify(oAuthToken));
        return true;
      } catch (e) {
        console.log('Fail saving oauth token in browser. ' + e);
        return false;
      }
    } else {
      return false;
    }
  }

  public getOAuthToken() {
    if (sessionStorage.getItem(this.MCATALOG_OAUTH_TOKEN)) {
      try {
        return JSON.parse(this.encrypt ? this.securityService.decrypt(sessionStorage.getItem(this.MCATALOG_OAUTH_TOKEN)) : sessionStorage.getItem(this.MCATALOG_OAUTH_TOKEN));
      } catch (e) {
        console.log('Fail retrieving oauth token from browser. ' + e);
        return null;
      }
    } else {
      return null;
    }
  }

  public setCenter(center) {
    if (center) {
      try {
        sessionStorage.setItem(this.MCATALOG_CENTER, this.encrypt ? this.securityService.encrypt(JSON.stringify(center)) : JSON.stringify(center));
        return true;
      } catch (e) {
        console.log('Fail saving center in browser. ' + e);
        return false;
      }
    } else {
      return false;
    }
  }

  public getCenter() {
    if (sessionStorage.getItem(this.MCATALOG_CENTER)) {
      try {
        return JSON.parse(this.encrypt ? this.securityService.decrypt(sessionStorage.getItem(this.MCATALOG_CENTER)) : sessionStorage.getItem(this.MCATALOG_CENTER));
      } catch (e) {
        console.log('Fail retrieving center from browser. ' + e);
        return null;
      }
    } else {
      return null;
    }
  }

  public setSmsPhone(smsPhone) {
    if (smsPhone) {
      try {
        sessionStorage.setItem(this.MCATALOG_SMS_PHONE, this.encrypt ? this.securityService.encrypt(JSON.stringify(smsPhone)) : JSON.stringify(smsPhone));
        return true;
      } catch (e) {
        console.log('Fail saving sms phone in browser. ' + e);
        return false;
      }
    } else {
      return false;
    }
  }

  public getSmsPhone() {
    if (sessionStorage.getItem(this.MCATALOG_SMS_PHONE)) {
      try {
        return JSON.parse(this.encrypt ? this.securityService.decrypt(sessionStorage.getItem(this.MCATALOG_SMS_PHONE)) : sessionStorage.getItem(this.MCATALOG_SMS_PHONE));
      } catch (e) {
        console.log('Fail retrieving sms phone from browser. ' + e);
        return null;
      }
    } else {
      return null;
    }
  }

  public setType(type) {
    if (type) {
      try {
        sessionStorage.setItem(this.MCATALOG_TYPE, this.encrypt ? this.securityService.encrypt(type) : type);
        return true;
      } catch (e) {
        console.log('Fail saving type in browser. ' + e);
        return false;
      }
    } else {
      return false;
    }
  }

  public getType() {
    if (sessionStorage.getItem(this.MCATALOG_TYPE)) {
      try {
        return this.encrypt ? this.securityService.decrypt(sessionStorage.getItem(this.MCATALOG_TYPE)) : sessionStorage.getItem(this.MCATALOG_TYPE);
      } catch (e) {
        console.log('Fail retrieving type from browser. ' + e);
        return null;
      }
    } else {
      return null;
    }
  }

  public setPayments(payments: Array<Payment>) {
    if (payments) {
      try {
        sessionStorage.setItem(this.MCATALOG_PAYMENTS, this.encrypt ? this.securityService.encrypt(JSON.stringify(payments)) : JSON.stringify(payments));
        return true;
      } catch (e) {
        console.log('Fail saving payments in browser. ' + e);
        return false;
      }
    } else {
      return false;
    }
  }

  public getPayments() {
    if (sessionStorage.getItem(this.MCATALOG_PAYMENTS)) {
      try {
        return JSON.parse(this.encrypt ? this.securityService.decrypt(sessionStorage.getItem(this.MCATALOG_PAYMENTS)) : sessionStorage.getItem(this.MCATALOG_PAYMENTS));
      } catch (e) {
        console.log('Fail retrieving payments from browser. ' + e);
        return null;
      }
    } else {
      return null;
    }
  }

  public setPayment(payment) {
    if (payment) {
      try {
        sessionStorage.setItem(this.MCATALOG_PAYMENT, this.encrypt ? this.securityService.encrypt(payment) : payment);
        return true;
      } catch (e) {
        console.log('Fail saving payment in browser. ' + e);
        return false;
      }
    } else {
      return false;
    }
  }

  public getPayment() {
    if (sessionStorage.getItem(this.MCATALOG_PAYMENT)) {
      try {
        return this.encrypt ? this.securityService.decrypt(sessionStorage.getItem(this.MCATALOG_PAYMENT)) : sessionStorage.getItem(this.MCATALOG_PAYMENT);
      } catch (e) {
        console.log('Fail retrieving payment from browser. ' + e);
        return null;
      }
    } else {
      return null;
    }
  }

  public setMCatalogBanner(mCatalogBanner) {
    if (mCatalogBanner) {
      try {
        sessionStorage.setItem(this.MCATALOG_BANNER, this.encrypt ? this.securityService.encrypt(mCatalogBanner) : mCatalogBanner);
        return true;
      } catch (e) {
        console.log('Fail saving banner in browser. ' + e);
        return false;
      }
    } else {
      return false;
    }
  }

  public getMCatalogBanner() {
    if (sessionStorage.getItem(this.MCATALOG_BANNER)) {
      try {
        return this.encrypt ? this.securityService.decrypt(sessionStorage.getItem(this.MCATALOG_BANNER)) : sessionStorage.getItem(this.MCATALOG_BANNER);
      } catch (e) {
        console.log('Fail retrieving banner from browser. ' + e);
        return null;
      }
    } else {
      return null;
    }
  }

  public setMCatalogPrimaryColor(mCatalogPrimaryColor) {
    if (mCatalogPrimaryColor) {
      try {
        sessionStorage.setItem(this.MCATALOG_PRIMARYCOLOR, this.encrypt ? this.securityService.encrypt(mCatalogPrimaryColor) : mCatalogPrimaryColor);
        return true;
      } catch (e) {
        console.log('Fail saving primary color in browser. ' + e);
        return false;
      }
    } else {
      return false;
    }
  }

  public getMCatalogPrimaryColor() {
    if (sessionStorage.getItem(this.MCATALOG_PRIMARYCOLOR)) {
      try {
        return this.encrypt ? this.securityService.decrypt(sessionStorage.getItem(this.MCATALOG_PRIMARYCOLOR)) : sessionStorage.getItem(this.MCATALOG_PRIMARYCOLOR);
      } catch (e) {
        console.log('Fail retrieving primary color from browser. ' + e);
        return null;
      }
    } else {
      return null;
    }
  }

  public setMCatalogSecondaryColor(mCatalogSecondaryColor) {
    if (mCatalogSecondaryColor) {
      try {
        sessionStorage.setItem(this.MCATALOG_SECONDARYCOLOR, this.encrypt ? this.securityService.encrypt(mCatalogSecondaryColor) : mCatalogSecondaryColor);
        return true;
      } catch (e) {
        console.log('Fail saving secondary color in browser. ' + e);
        return false;
      }
    } else {
      return false;
    }
  }

  public getMCatalogSecondaryColor() {
    if (sessionStorage.getItem(this.MCATALOG_SECONDARYCOLOR)) {
      try {
        return this.encrypt ? this.securityService.decrypt(sessionStorage.getItem(this.MCATALOG_SECONDARYCOLOR)) : sessionStorage.getItem(this.MCATALOG_SECONDARYCOLOR);
      } catch (e) {
        console.log('Fail retrieving secondary color from browser. ' + e);
        return null;
      }
    } else {
      return null;
    }
  }

  public setMCatalogInactivityTime(mCataloginactivityTime) {
    if (mCataloginactivityTime) {
      try {
        sessionStorage.setItem(this.MCATALOG_INACTIVITYTIME, this.encrypt ? this.securityService.encrypt(mCataloginactivityTime) : mCataloginactivityTime);
        return true;
      } catch (e) {
        console.log('Fail saving inactivity time in browser. ' + e);
        return false;
      }
    } else {
      return false;
    }
  }

  public getMCatalogInactivityTime() {
    if (sessionStorage.getItem(this.MCATALOG_INACTIVITYTIME)) {
      try {
        return this.encrypt ? this.securityService.decrypt(sessionStorage.getItem(this.MCATALOG_INACTIVITYTIME)) : sessionStorage.getItem(this.MCATALOG_INACTIVITYTIME);
      } catch (e) {
        console.log('Fail retrieving inactivity time from browser. ' + e);
        return null;
      }
    } else {
      return null;
    }
  }

  public setMCatalogAssistanceActive(mCatalogAssistanceActive) {
    if (mCatalogAssistanceActive) {
      try {
        sessionStorage.setItem(this.MCATALOG_ASSISTANCEACTIVE, this.encrypt ? this.securityService.encrypt(mCatalogAssistanceActive) : mCatalogAssistanceActive);
        return true;
      } catch (e) {
        console.log('Fail saving assistance active in browser. ' + e);
        return false;
      }
    } else {
      return false;
    }
  }

  public getMCatalogAssistanceActive() {
    if (sessionStorage.getItem(this.MCATALOG_ASSISTANCEACTIVE)) {
      try {
        return this.encrypt ? this.securityService.decrypt(sessionStorage.getItem(this.MCATALOG_ASSISTANCEACTIVE)) : sessionStorage.getItem(this.MCATALOG_ASSISTANCEACTIVE);
      } catch (e) {
        console.log('Fail retrieving assistance active from browser. ' + e);
        return null;
      }
    } else {
      return null;
    }
  }

  public setBankTransaction(bankTransaction) {
    if (bankTransaction) {
      try {
        sessionStorage.setItem(this.MCATALOG_BANK_TRANSACTION, this.encrypt ? this.securityService.encrypt(bankTransaction) : bankTransaction);
        return true;
      } catch (e) {
        console.log('Fail saving bank transaction in browser. ' + e);
        return false;
      }
    } else {
      return false;
    }
  }

  public getBankTransaction() {
    if (sessionStorage.getItem(this.MCATALOG_BANK_TRANSACTION)) {
      try {
        return this.encrypt ? this.securityService.decrypt(sessionStorage.getItem(this.MCATALOG_BANK_TRANSACTION)) : sessionStorage.getItem(this.MCATALOG_BANK_TRANSACTION);
      } catch (e) {
        console.log('Fail retrieving bank transaction from browser. ' + e);
        return null;
      }
    } else {
      return null;
    }
  }

  public getCustomerName() {
    if (sessionStorage.getItem(this.MCATALOG_CUSTOMER_NAME)) {
      try {
        return this.encrypt ? this.securityService.decrypt(sessionStorage.getItem(this.MCATALOG_CUSTOMER_NAME)) : sessionStorage.getItem(this.MCATALOG_CUSTOMER_NAME);
      } catch (e) {
        console.log('Fail retrieving bank transaction from browser. ' + e);
        return null;
      }
    } else {
      return null;
    }
  }

  public setCustomerName(customerName) {
    if (customerName) {
      try {
        sessionStorage.setItem(this.MCATALOG_CUSTOMER_NAME, this.encrypt ? this.securityService.encrypt(customerName) : customerName);
        return true;
      } catch (e) {
        console.log('Fail saving bank transaction in browser. ' + e);
        return false;
      }
    } else {
      return false;
    }
  }

  public getCustomerPhone() {
    if (sessionStorage.getItem(this.MCATALOG_CUSTOMER_PHONE)) {
      try {
        return this.encrypt ? this.securityService.decrypt(sessionStorage.getItem(this.MCATALOG_CUSTOMER_PHONE)) : sessionStorage.getItem(this.MCATALOG_CUSTOMER_PHONE);
      } catch (e) {
        console.log('Fail retrieving bank transaction from browser. ' + e);
        return null;
      }
    } else {
      return null;
    }
  }

  public setCustomerPhone(customerPhone) {
    if (customerPhone) {
      try {
        sessionStorage.setItem(this.MCATALOG_CUSTOMER_PHONE, this.encrypt ? this.securityService.encrypt(customerPhone) : customerPhone);
        return true;
      } catch (e) {
        console.log('Fail saving bank transaction in browser. ' + e);
        return false;
      }
    } else {
      return false;
    }
  }

  public getCustomerEmail() {
    if (sessionStorage.getItem(this.MCATALOG_CUSTOMER_EMAIL)) {
      try {
        return this.encrypt ? this.securityService.decrypt(sessionStorage.getItem(this.MCATALOG_CUSTOMER_EMAIL)) : sessionStorage.getItem(this.MCATALOG_CUSTOMER_EMAIL);
      } catch (e) {
        console.log('Fail retrieving bank transaction from browser. ' + e);
        return null;
      }
    } else {
      return null;
    }
  }

  public setCustomerEmail(customerEmail) {
    if (customerEmail) {
      try {
        sessionStorage.setItem(this.MCATALOG_CUSTOMER_EMAIL, this.encrypt ? this.securityService.encrypt(customerEmail) : customerEmail);
        return true;
      } catch (e) {
        console.log('Fail saving bank transaction in browser. ' + e);
        return false;
      }
    } else {
      return false;
    }
  }

  public setOrderId(orderId) {
    if (orderId) {
      try {
        sessionStorage.setItem(this.MCATALOG_ORDER_ID, this.encrypt ? this.securityService.encrypt(orderId) : orderId);
        return true;
      } catch (e) {
        console.log('Fail saving order id in browser. ' + e);
        return false;
      }
    } else {
      return false;
    }
  }

  public getOrderId() {
    if (sessionStorage.getItem(this.MCATALOG_ORDER_ID)) {
      try {
        return this.encrypt ? this.securityService.decrypt(sessionStorage.getItem(this.MCATALOG_ORDER_ID)) : sessionStorage.getItem(this.MCATALOG_ORDER_ID);
      } catch (e) {
        console.log('Fail retrieving order id from browser. ' + e);
        return null;
      }
    } else {
      return null;
    }
  }

  public setTicketHTML(ticketHTML) {
    if (ticketHTML) {
      try {
        sessionStorage.setItem(this.MCATALOG_TICKET_HTML, this.encrypt ? this.securityService.encrypt(ticketHTML) : ticketHTML);
        return true;
      } catch (e) {
        console.log('Fail saving ticket html in browser. ' + e);
        return false;
      }
    } else {
      return false;
    }
  }

  public getTicketHTML() {
    if (sessionStorage.getItem(this.MCATALOG_TICKET_HTML)) {
      try {
        return this.encrypt ? this.securityService.decrypt(sessionStorage.getItem(this.MCATALOG_TICKET_HTML)) : sessionStorage.getItem(this.MCATALOG_TICKET_HTML);
      } catch (e) {
        console.log('Fail retrieving ticket html from browser. ' + e);
        return null;
      }
    } else {
      return null;
    }
  }

  public setCart(cart: Array<CartItem>) {
    if (cart) {
      try {
        sessionStorage.setItem(this.MCATALOG_CART, this.encrypt ? this.securityService.encrypt(JSON.stringify(cart)) : JSON.stringify(cart));
        return true;
      } catch (e) {
        console.log('Fail saving cart in browser. ' + e);
        return false;
      }
    } else {
      return false;
    }
  }

  public getCart() {
    if (sessionStorage.getItem(this.MCATALOG_CART)) {
      try {
        return JSON.parse(this.encrypt ? this.securityService.decrypt(sessionStorage.getItem(this.MCATALOG_CART)) : sessionStorage.getItem(this.MCATALOG_CART));
      } catch (e) {
        console.log('Fail retrieving cart from browser. ' + e);
        return null;
      }
    } else {
      return null;
    }
  }

  public deleteRegisterToken() {
    sessionStorage.removeItem(this.MCATALOG_REGISTER_TOKEN);
  }

  public deleteAllItems() {
    sessionStorage.removeItem(this.MCATALOG_LANG_ID);
    sessionStorage.removeItem(this.MCATALOG_CONTEXT_ID);
    sessionStorage.removeItem(this.MCATALOG_DEVICEDATA_ID);
    sessionStorage.removeItem(this.MCATALOG_OAUTH_TOKEN);
    sessionStorage.removeItem(this.MCATALOG_CENTER);
    sessionStorage.removeItem(this.MCATALOG_REGISTER_TOKEN);
    sessionStorage.removeItem(this.MCATALOG_SMS_PHONE);
    sessionStorage.removeItem(this.MCATALOG_TYPE);
    sessionStorage.removeItem(this.MCATALOG_CART);
    sessionStorage.removeItem(this.MCATALOG_ORDER_ID);
    sessionStorage.removeItem(this.MCATALOG_PAYMENT);
    sessionStorage.removeItem(this.MCATALOG_PAYMENTS);
    sessionStorage.removeItem(this.MCATALOG_BANNER);
    sessionStorage.removeItem(this.MCATALOG_PRIMARYCOLOR);
    sessionStorage.removeItem(this.MCATALOG_BANK_TRANSACTION);
    sessionStorage.removeItem(this.MCATALOG_TICKET_HTML);
    sessionStorage.removeItem(this.MCATALOG_ASSISTANCEACTIVE);
  }

  public deleteTemporalItems() {
    sessionStorage.removeItem(this.MCATALOG_SMS_PHONE);
    sessionStorage.removeItem(this.MCATALOG_TYPE);
    sessionStorage.removeItem(this.MCATALOG_BANK_TRANSACTION);
    sessionStorage.removeItem(this.MCATALOG_CART);
    sessionStorage.removeItem(this.MCATALOG_PAYMENTS);
    sessionStorage.removeItem(this.MCATALOG_PAYMENT);
    sessionStorage.removeItem(this.MCATALOG_ORDER_ID);
    sessionStorage.removeItem(this.MCATALOG_TICKET_HTML);
  }

}
