import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpHeaders, HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CompanyDaoService {

  private BASE_URL = environment.apiURL;
  private OPTIONS = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json; charset=UTF-8'
    })
  };

  constructor(private http: HttpClient) { }

  public getCenter(context , lang) {
    const options = {};
    Object.assign(options, this.OPTIONS);
    options['headers'] = options['headers'].append('context', context);
    options['headers'] = options['headers'].append('lang', lang);
    const item = { };
    return this.http.post(this.BASE_URL + 'api/v2.0/center/ws_getCenterInfo', item, options);
  }

  public getLegalTerms(context , lang) {
    const options = {};
    Object.assign(options, this.OPTIONS);
    options['headers'] = options['headers'].append('context', context);
    options['headers'] = options['headers'].append('lang', lang);
    const item = { };
    return this.http.post(this.BASE_URL +  'api/v2.0/legalTerms/list/session', item, options);
  }

  public getMCatalogServices(context , lang) {
    const options = {};
    Object.assign(options, this.OPTIONS);
    options['headers'] = options['headers'].append('context', context);
    options['headers'] = options['headers'].append('lang', lang);
    return this.http.get(this.BASE_URL +  'api/v2.0/mcatalog/config/services', options);
  }

  public getMCatalogConfig(context , lang) {
    const options = {};
    Object.assign(options, this.OPTIONS);
    options['headers'] = options['headers'].append('context', context);
    options['headers'] = options['headers'].append('lang', lang);
    return this.http.get(this.BASE_URL +  'api/v2.0/mcatalog/config', options);
  }
}
