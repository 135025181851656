import { Price } from './price.model';
import { Tax } from './tax.model';

export class ConfirmProduct {

    private quantity: number;
    private gtin: string;
    private internalCode: string;
    private description: string;
    private tradename: string;
    private image: string;
    private pricePVP: Price;
    private pricePVPOffer: Price;
    private taxAmount: Tax;
    private preparation: string;
    private zoneInternalId: string;
    private category: string;
    private categoryCode: string;
    private measure: number;
    private measureUnit: string;

    constructor(quantity?: number, gtin?: string, internalCode?: string, description?: string, tradename?: string, image?: string, pricePVP?: Price, pricePVPOffer?: Price, taxAmount?: Tax, preparation?: string, zoneInternalId?: string, category?: string, categoryCode?: string, measure?: number, measureUnit?: string) {
        this.quantity = quantity;
        this.gtin = gtin;
        this.internalCode = internalCode;
        this.description = description;
        this.tradename = tradename;
        this.image = image;
        this.pricePVP = pricePVP;
        this.pricePVPOffer = pricePVPOffer;
        this.taxAmount = taxAmount;
        this.preparation = preparation;
        this.zoneInternalId = zoneInternalId;
        this.category = category;
        this.categoryCode = categoryCode;
        this.measure = measure;
        this.measureUnit = measureUnit;
    }

    public get Quantity(): number {
        return this.quantity;
    }

    public set Quantity(quantity: number) {
        this.quantity = quantity;
    }

    public get GTIN(): string {
        return this.gtin;
    }

    public set GTIN(gtin: string) {
        this.gtin = gtin;
    }

    public get InternalCode(): string {
        return this.internalCode;
    }

    public set InternalCode(internalCode: string) {
        this.internalCode = internalCode;
    }

    public get Description(): string {
        return this.description;
    }

    public set Description(description: string) {
        this.description = description;
    }

    public get Tradename() {
        return this.tradename;
    }

    public set Tradename(tradename: string) {
        this.tradename = tradename;
    }

    public get Image() {
        return this.image;
    }

    public set Image(image: string) {
        this.image = image;
    }

    public get PricePVP(): Price {
        return this.pricePVP;
    }

    public set PricePVP(pricePVP: Price) {
        this.pricePVP = pricePVP;
    }

    public get PricePVPOffer(): Price {
        return this.pricePVPOffer;
    }

    public set PricePVPOffer(pricePVPOffer: Price) {
        this.pricePVPOffer = pricePVPOffer;
    }

    public get TaxAmount(): Tax {
        return this.taxAmount;
    }

    public set TaxAmount(taxAmount: Tax) {
        this.taxAmount = taxAmount;
    }

    public get Preparation(): string {
        return this.preparation;
    }

    public set Preparation(preparation: string) {
        this.preparation = preparation;
    }

    public get ZoneInternalId(): string {
        return this.zoneInternalId;
    }

    public set ZoneInternalId(zoneInternalId: string) {
        this.zoneInternalId = zoneInternalId;
    }

    public get Category(): string {
        return this.category;
    }

    public set Category(category: string) {
        this.category = category;
    }

    public get CategoryCode(): string {
        return this.categoryCode;
    }

    public set CategoryCode(categoryCode: string) {
        this.categoryCode = categoryCode;
    }

    public get Measure(): number {
        return this.measure;
    }

    public set Measure(measure: number) {
        this.measure = measure;
    }

    public get MeasureUnit(): string {
        return this.measureUnit;
    }

    public set MeasureUnit(measureUnit: string) {
        this.measureUnit = measureUnit;
    }
}
