import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";

@Component({
  selector: 'app-redirect',
  templateUrl: './redirect.component.html',
  styleUrls: ['./redirect.component.scss']
})
export class RedirectComponent implements OnInit {

  constructor(    
    private router: Router,
    private activatedRoute: ActivatedRoute
    ) {
    this.router.navigate(['./registered/layout/products' ,this.activatedRoute.snapshot.paramMap.get("catalog")]);
   }

  ngOnInit() {

  }

}
