import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { take } from 'rxjs/internal/operators';
import { tap } from 'rxjs/internal/operators/tap';
import { Subscription } from 'rxjs/internal/Subscription';
import { CompanyService } from 'src/app/services/company-services/company.service';
import { InactivityService } from 'src/app/services/inactivity-service/inactivity.service';
import { StorageService } from 'src/app/services/storage-service/storage.service';
import { DialogRef } from '../dialog/dialog-ref';

@Component({
  selector: 'app-legal-terms-dialog',
  templateUrl: './legal-terms-dialog.component.html',
  styleUrls: ['./legal-terms-dialog.component.scss']
})
export class LegalTermsDialogComponent implements OnInit, OnDestroy {

  legalTerms;
  primaryColor;
  getLegalTermsSubscription: Subscription;

  constructor(private dialogRef: DialogRef,
              private storageService: StorageService,
              private inactivityService: InactivityService,
              private centerService: CompanyService) { }

  ngOnInit() {
    this.primaryColor = this.storageService.getMCatalogPrimaryColor();
    this.getLegalTerms();
  }

  @HostListener('click', ['$event'])
  public click(e) {
    // this.inactivityService.reloadInactivityTimer();
  }

  public getLegalTerms() {
    this.getLegalTermsSubscription = this.centerService.getLegalTerms().pipe(
      take(1),
      tap(res => res[0] ? this.legalTerms = res[0] : null)
    ).subscribe();
  }

  public closeLegalTerms() {
    this.dialogRef.close(true);
  }

  ngOnDestroy() {
    if (this.getLegalTermsSubscription) { this.getLegalTermsSubscription.unsubscribe(); }
  }
}
