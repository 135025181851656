import { Injectable, isDevMode } from '@angular/core';
import { OauthLibService } from 'oauth-lib';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment';

declare var require: any;
const aesjs = require('aes-js');


@Injectable({
  providedIn: 'root'
})
export class SecurityService {

  private key = [6, 1, 2, 0, 8, 3, 3, 4, 3, 3, 3, 1, 9, 4, 8, 9];

  constructor(private oauthService: OauthLibService) { }

  public requestOAuthToken() {
    return new Observable(
      (o) => {
        this.oauthService.requestToken(environment.oauth.https, environment.oauth.host, '2.0', environment.oauth.client_id, environment.oauth.client_secret, 'client_credentials', 'read').subscribe(
          (res) => {
            if (isDevMode()) { console.dir(res); }
            o.next(res);
          },
          (error) => {
            o.error(error);
          }
        );
      }
    );
  }

  public encrypt(text: string) {
    const textBytes = aesjs.utils.utf8.toBytes(text);
    const aesCtr = new aesjs.ModeOfOperation.ctr(this.key, new aesjs.Counter(5));
    const encryptedBytes = aesCtr.encrypt(textBytes);
    const encryptedHex = aesjs.utils.hex.fromBytes(encryptedBytes);
    return encryptedHex;
  }

  public decrypt(encryptedHex) {
    const encryptedBytes = aesjs.utils.hex.toBytes(encryptedHex);
    const aesCtr = new aesjs.ModeOfOperation.ctr(this.key, new aesjs.Counter(5));
    const decryptedBytes = aesCtr.decrypt(encryptedBytes);
    const decryptedText = aesjs.utils.utf8.fromBytes(decryptedBytes);
    return decryptedText;
  }
}
