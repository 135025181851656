import { Injectable, isDevMode } from '@angular/core';
import { environment } from 'src/environments/environment';
import { SocketsLibService, ReactiveSocket } from 'sockets-lib';
import { Observable } from 'rxjs/internal/Observable';

interface LimboChannel {
  url: string;
  channel: string;
  path: string;
}

@Injectable({
  providedIn: 'root'
})
export class CommunicationDaoService {

  private BASE_URL = environment.apiURL;

  constructor() { }


}
