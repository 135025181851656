export class Tax {

    private significand: number;
    private exponent: number;

    constructor(significand?: number, exponent?: number) {
        this.significand = significand;
        this.exponent = exponent;
    }

    public get Significand() {
        return this.significand;
    }

    public set Significand(significand) {
        this.significand = significand;
    }

    public get Exponent() {
        return this.exponent;
    }

    public set Exponent(exponent) {
        this.exponent = exponent;
    }
}
