export class PaymentType {

    private code: string;
    private description: string;
    private internalId: string;

    constructor(code?: string, description?: string, internalId?: string) {
        this.code = code;
        this.description = description;
        this.internalId = internalId;
    }

    public get Code(): string {
        return this.code;
    }

    public set Code(code: string) {
        this.code = code;
    }

    public get Description() {
        return this.description;
    }

    public set Description(description: string) {
        this.description = description;
    }

    public get InternalId() {
        return this.internalId;
    }

    public set InternalId(internalId: string) {
        this.internalId = internalId;
    }
}
