import { Component, OnInit } from '@angular/core';
import { timer } from 'rxjs/internal/observable/timer';
import { tap } from 'rxjs/internal/operators/tap';
import { Router } from '@angular/router';
import { InactivityService } from 'src/app/services/inactivity-service/inactivity.service';
import { StorageService } from 'src/app/services/storage-service/storage.service';
import { Location } from '@angular/common'
import { CartService } from "src/app/services/cart-services/cart.service";


@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss']
})
export class ConfirmationComponent implements OnInit {

  lottieConfig;
  banner;
  order;
  total;

  constructor(private inactivityService: InactivityService,
              private storageService: StorageService,
              private router: Router,
              private location: Location,
              private cartService: CartService,
              ) { }

  ngOnInit() {
    this.setLottieAnimation();
    this.banner = this.storageService.getMCatalogBanner();
    this.inactivityService.cancelInactivityTimer();
    this.order = this.cartService.getCart();
    this.total = this.cartService.getCartTotal();
    // const gotoWelcomeTimer = timer(15000);
    // gotoWelcomeTimer.pipe(
    //   tap(o => {
    //     this.router.navigate(['/registered/welcome']);
    //   })
    // ).subscribe();
  }

  public goHome(){
    this.router.navigate(['/registered/welcome']);
  }

  public cancel(): void {
    this.location.back()  
  }

  private setLottieAnimation() {
    this.lottieConfig = {
      path: 'assets/animations/confirmation.animation.json',
      renderer: 'canvas',
      autoplay: true,
      loop: true
    };
  }
}
