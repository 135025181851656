import { Component, OnInit, OnDestroy, ChangeDetectorRef } from "@angular/core";
import { CartService } from "src/app/services/cart-services/cart.service";
import { tap } from "rxjs/internal/operators/tap";
import { Subscription } from "rxjs";
import { Router, ActivatedRoute, RouterLinkActive } from "@angular/router";
import { StorageService } from "src/app/services/storage-service/storage.service";
import { DialogService } from "../dialog/dialog.service";
import { CancelOrderDialogComponent } from "../cancel-order-dialog/cancel-order-dialog.component";
import { CatalogsService } from "src/app/services/catalogs-services/catalogs.service";
import { PaymentService } from "src/app/services/payments-services/payment.service";
import { TranslateService } from "@ngx-translate/core";
import { take } from "rxjs/internal/operators/take";
import { RfidService } from "src/app/services/rfid-services/rfid.service";
import { MhelpService } from "src/app/services/mhelp-services/mhelp.service";
@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
})
export class FooterComponent implements OnInit {
  center;
  banner;
  catalogs;
  catalog;
  loadingCatalog;
  order;
  total;
  primaryColor;
  secondaryColor;
  assistanceActive;
  listenCatalogsRefreshSubscription: Subscription;
  listenCatalogRefreshSubscription: Subscription;
  listenChangesSubscription: Subscription;
  home = false;
  search = false;
  cart = false;
  cant;

  constructor(
    private cartService: CartService,
    private dialogService: DialogService,
    private router: Router
  ) {}

  ngOnInit() {
    this.listenCartChangesRefresh();
    this.order = this.cartService.getCart();
    this.total = this.cartService.getCartTotal();
    this.cant = this.order.length;
    console.log("this.order ->")
    console.log(this.order)
  }

  public cancelOrder() {
    const dialog = this.dialogService.open(CancelOrderDialogComponent);
    dialog.afterClosed
      .pipe(
        take(1),
        tap((o) => {
          if (o) {
            this.cartService.emptyCart();
            this.gotoWelcome();
          }
        })
      )
      .subscribe();
  }

  private gotoWelcome() {
    this.router.navigate(["/registered/welcome"]);
  }

  private listenCartChangesRefresh() {
    if (this.listenChangesSubscription) {
      this.listenChangesSubscription.unsubscribe();
    }
    this.listenChangesSubscription = this.cartService
      .listenChanges()
      .pipe(
        tap((o) => (this.order = o)),
        tap((o) => (this.total = this.cartService.getCartTotal())),
        tap((o) => (this.cant = this.order.length))
      )
      .subscribe();
  }
}
