export class Desk {

    private turnDeskInternalId: string;

    constructor(turnDeskInternalId?: string) {
        this.turnDeskInternalId = turnDeskInternalId;
    }
    public get TurnDeskInternalId(): string {
        return this.turnDeskInternalId;
    }

    public set TurnDeskInternalId(turnDeskInternalId: string) {
        this.turnDeskInternalId = turnDeskInternalId;
    }
}
