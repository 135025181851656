import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-catalog-card-nav',
  templateUrl: './catalog-card-nav.component.html',
  styleUrls: ['./catalog-card-nav.component.scss']
})
export class CatalogCardNavComponent implements OnInit {

  @Input() title;
  @Input() image;
  @Input() selected;
  @Input() backgroundColor;
  @Input() textColor;

  constructor() { }

  ngOnInit() {
  }

}
