import { Injectable } from '@angular/core';
declare const Android;

@Injectable({
  providedIn: 'root'
})
export class RfidService {

  constructor() { }

  public switchOnRfid() {
    try {
      // tslint:disable-next-line:only-arrow-functions
      (function() { Android.switchOnRfid(); })();
    } catch (e) {
      console.dir(e);
    }
  }

  public switchOffRfid() {
    try {
      // tslint:disable-next-line:only-arrow-functions
      (function() { Android.switchOffRfid(); })();
    } catch (e) {
      console.dir(e);
    }
  }

}
