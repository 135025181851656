import { Pipe, PipeTransform } from '@angular/core';
import { FingerprintService } from '../services/fingerprint-services/fingerprint.service';

@Pipe({
  name: 'price'
})
export class PricePipe implements PipeTransform {

  constructor(private fingerPrintService: FingerprintService) {}

  transform(value: any, ...args: any[]): any {
    if (value) {
      if (args[0] === 'flat') {
        return (value.Significand * Math.pow(10, value.Exponent)).toFixed(2).replace(new RegExp(/,|\./g), '');
      }
      return parseFloat((value.Significand * Math.pow(10, value.Exponent)).toFixed(2)).toLocaleString(this.fingerPrintService.getLanguage().replace('_', '-')) + value.Currency;
    }
    return null;
  }

}
