import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { interval, Subscription } from 'rxjs';
import { tap } from 'rxjs/internal/operators/tap';
import { OrderService } from 'src/app/services/order-services/order.service';
import { StorageService } from 'src/app/services/storage-service/storage.service';
import { flatMap, take } from 'rxjs/internal/operators';

@Component({
  selector: 'app-waiting-mpay',
  templateUrl: './waiting-mpay.component.html',
  styleUrls: ['./waiting-mpay.component.scss']
})
export class WaitingPayComponent implements OnInit, OnDestroy {

  dataphoneConfig;
  errorConfig;
  center;
  banner;
  primaryColor;
  mpayUrl;
  checkOrderTimerSubscription: Subscription;

  constructor(private storageService: StorageService,
              private orderService: OrderService,
              private router: Router,
              private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.mpayUrl = this.activatedRoute.snapshot.params['mpayUrl'];
    this.center = this.storageService.getCenter();
    this.banner = this.storageService.getMCatalogBanner();
    this.primaryColor = this.storageService.getMCatalogPrimaryColor();
    this.checkOrderStatus();
  }

  public goback() {
    this.router.navigate(['/registered/layout/payment']);
  }

  private checkOrderStatus() {
    this.checkOrderTimerSubscription = interval(5000).pipe(
      flatMap(t => this.isPayed().pipe(
        tap(o => {
          if (o === 'ORDER_READY_TO_PICKING') {
            if (this.checkOrderTimerSubscription) { this.checkOrderTimerSubscription.unsubscribe(); }
            this.router.navigate(['/registered/printing']);
          }
        })
      ))
    ).subscribe();
  }

  private isPayed() {
    return this.orderService.getOrderStatus().pipe(
      take(1),
      tap(o => o === 'ORDER_READY_TO_PICKING' ? true : false)
    );
  }

  ngOnDestroy() {
    if (this.checkOrderTimerSubscription) { this.checkOrderTimerSubscription.unsubscribe(); }
  }
}


