export class PaymentPeripheral {

    private internalId: string;
    private peripheralName: string;
    private connectionType: string;
    private connectionPort: string;
    private connectionIpAddress: string;
    private productId: string;
    private vendorId: string;

    constructor(internalId?: string, peripheralName?: string, connectionType?: string, connectionPort?: string, connectionIpAddress?: string, productId?: string, vendorId?: string) {
        this.internalId = internalId;
        this.peripheralName = peripheralName;
        this.connectionType = connectionType;
        this.connectionPort = connectionPort;
        this.connectionIpAddress = connectionIpAddress;
        this.productId = productId;
        this.vendorId = vendorId;
    }

    public get InternalId(): string {
        return this.internalId;
    }

    public set InternalId(internalId: string) {
        this.internalId = internalId;
    }

    public get PeripheralName(): string {
        return this.peripheralName;
    }

    public set PeripheralName(peripheralName: string) {
        this.peripheralName = peripheralName;
    }

    public get ConnectionType(): string {
        return this.connectionType;
    }

    public set ConnectionType(connectionType: string) {
        this.connectionType = connectionType;
    }

    public get ConnectionPort(): string {
        return this.connectionPort;
    }

    public set ConnectionPort(connectionPort: string) {
        this.connectionPort = connectionPort;
    }

    public get ConnectionIpAddress(): string {
        return this.connectionIpAddress;
    }

    public set ConnectionIpAddress(connectionIpAddress: string) {
        this.connectionIpAddress = connectionIpAddress;
    }

    public get ProductId(): string {
        return this.productId;
    }

    public set ProductId(productId: string) {
        this.productId = productId;
    }

    public get VendorId(): string {
        return this.vendorId;
    }

    public set VendorId(vendorId: string) {
        this.vendorId = vendorId;
    }
}