export class Service {

    private active: boolean;
    private internalId: string;
    private service: string;


    constructor(active?: boolean, internalId?: string, service?: string) {
        this.active = active;
        this.internalId = internalId;
        this.service = service;
    }

    public get Active(): boolean {
        return this.active;
    }

    public set Active(active: boolean) {
        this.active = active;
    }

    public get InternalId(): string {
        return this.internalId;
    }

    public set InternalId(internalId: string) {
        this.internalId = internalId;
    }

    public get Service(): string {
        return this.service;
    }

    public set Service(service: string) {
        this.service = service;
    }
}
