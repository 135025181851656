import { Component, OnInit, HostListener } from '@angular/core';
import { InactivityService } from 'src/app/services/inactivity-service/inactivity.service';
import { StorageService } from 'src/app/services/storage-service/storage.service';
import { DialogRef } from '../dialog/dialog-ref';

@Component({
  selector: 'app-cancel-order-dialog',
  templateUrl: './cancel-order-dialog.component.html',
  styleUrls: ['./cancel-order-dialog.component.scss']
})
export class CancelOrderDialogComponent implements OnInit {

  primaryColor;

  constructor(private inactivityService: InactivityService,
              private storageService: StorageService,
              private dialog: DialogRef) { }

  ngOnInit() {
    this.primaryColor = this.storageService.getMCatalogPrimaryColor();
  }

  @HostListener('click', ['$event'])
  public click(e) {
    // this.inactivityService.reloadInactivityTimer();
  }

  public cancel() {
    this.dialog.close();
  }

  public accept() {
    this.dialog.close(true);
  }
}
