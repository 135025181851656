import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { DialogService } from "../dialog/dialog.service";
import { DeleteProductDialogComponent } from "../delete-product-dialog/delete-product-dialog.component";
import { tap } from "rxjs/internal/operators/tap";
import { Price } from "src/app/models/price.model";
import { take } from "rxjs/internal/operators";
import { StorageService } from "src/app/services/storage-service/storage.service";
import { CartService } from "src/app/services/cart-services/cart.service";
import { Subscription } from "rxjs";

@Component({
  selector: "app-order-item",
  templateUrl: "./order-item.component.html",
  styleUrls: ["./order-item.component.scss"],
})
export class OrderItemComponent implements OnInit {
  listenChangesSubscription: Subscription;

  @Input() image;
  @Input() description;
  @Input() preparation;
  @Input() qty;
  @Input() price;
  @Input() priceOffer;
  @Input() measure;
  @Input() measureUnit;
  @Input() index;
  @Output() delete = new EventEmitter();
  primaryColor;
  order;
  total;
  cant;

  constructor(
    private storageService: StorageService,
    private dialogService: DialogService,
    private cartService: CartService
  ) {}

  ngOnInit() {
    this.primaryColor = this.storageService.getMCatalogPrimaryColor();
    this.order = this.cartService.getCart();
    this.total = this.cartService.getCartTotal();
    console.log(this.price)

    this.cartService
      .listenChanges()
      .pipe(
        tap((o) => (this.order = this.cartService.getCart())),
        tap((o) => (this.total = this.cartService.getCartTotal()))
      )
      .subscribe();
  }

  public del() {
    const dialog = this.dialogService.open(DeleteProductDialogComponent);
    dialog.afterClosed
      .pipe(
        take(1),
        tap((o) => (o ? this.delete.emit() : null))
      )
      .subscribe();
  }

  public getSubtotal(price) {
    const p = new Price();
    Object.assign(p, {
      significand: price.Significand,
      exponent: price.Exponent,
      currency: price.Currency,
    });
    p.Significand = (p.Significand / this.measure) * this.qty;
    return p;
  }

  public add() {
    switch (this.measureUnit) {
      case "ud":
      case "qty":
      case "inch":
      case "oz":
      case "kg":
        this.qty += 1;
        break;
      case "gr":
      case "ml":
      case "mm":
        this.qty += 100;
        break;
    }
    this.cartService.qtyChange(this.index, this.qty);
    this.cartService.listenChanges();
  }

  public subtract() {
    switch (this.measureUnit) {
      case "ud":
      case "qty":
      case "oz":
      case "inch":
      case "kg":
        if (this.qty > 1) {
          this.qty - 1 > 0 ? (this.qty -= 1) : null;
          this.cartService.qtyChange(this.index, this.qty);
          this.cartService.listenChanges();
        } else {
          this.cartService.deleteItem(this.index);
        }
        break;
      case "ml":
      case "mm":
      case "gr":
        if (this.qty > 100) {
          this.qty - 100 > 0 ? (this.qty -= 100) : null;
          this.cartService.qtyChange(this.index, this.qty);
          this.cartService.listenChanges();
        } else {
          this.cartService.deleteItem(this.index);
        }
        break;
    }
  }
}
