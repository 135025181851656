import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { LayoutComponent } from './components/layout/layout.component';
import { WelcomeComponent } from './components/welcome/welcome.component';
import { CatalogsComponent } from './components/catalogs/catalogs.component';
import { ProductsComponent } from './components/products/products.component';
import { RedirectComponent } from './components/redirect/redirect.component';
import { OrderComponent } from './components/order/order.component';
import { ConfirmationComponent } from './components/confirmation/confirmation.component';
import { RegisteredComponent } from './components/registered/registered.component';
import { PaymentComponent } from './components/payment/payment.component';
import { UserDataComponent } from './components/user-data/user-data.component';
import { WaitingDataphoneComponent } from './components/waiting-dataphone/waiting-dataphone.component';
import { PrintingComponent } from './components/printing/printing.component';
import { TypeComponent } from './components/type/type.component';
import { PaymentErrorComponent } from './components/payment-error/payment-error.component';
import { WaitingPayComponent } from './components/waiting-mpay/waiting-mpay.component';
import { SearchComponent } from './components/search/search.component';



const routes: Routes = [
  { path: ':urlContext', component: AppComponent },
  // { path: 'id/:manualudid', component: AppComponent },
  { path: 'registered', component: RegisteredComponent, children: [
    { path: 'welcome', component: WelcomeComponent },
    { path: 'layout', component: LayoutComponent, children: [
      { path: '', redirectTo: 'catalogs', pathMatch: 'full' },
      { path: 'type', component: TypeComponent },
      { path: 'catalogs/:brouse', component: CatalogsComponent },
      { path: 'products/:catalog', component: ProductsComponent },
      { path: 'redirect/:catalog', component: RedirectComponent },
      { path: 'order', component: OrderComponent },
      { path: 'search', component: SearchComponent },
      { path: 'payment', component: PaymentComponent },
      { path: 'userdata', component: UserDataComponent },
      { path: 'payment-error', component: PaymentErrorComponent },
      { path: 'confirmation', component: ConfirmationComponent }
    ]},
    { path: 'printing', component: PrintingComponent },
    { path: 'printing/:optionaldata', component: PrintingComponent },
    { path: 'waiting-dataphone/:gatewayInternalId', component: WaitingDataphoneComponent },
    { path: 'waiting-pay/:mpayUrl', component: WaitingPayComponent }
  ] }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
