import { Component, OnInit, Input, OnDestroy, OnChanges, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { StorageService } from 'src/app/services/storage-service/storage.service';
import { DialogService } from '../dialog/dialog.service';
import { ProductDialogComponent } from '../product-dialog/product-dialog.component';
import { Router, ActivatedRoute } from "@angular/router";
import { CatalogsService } from "src/app/services/catalogs-services/catalogs.service";
import { tap } from "rxjs/internal/operators/tap";
import { take } from "rxjs/internal/operators/take";



@Component({
  selector: 'app-products-grid',
  templateUrl: './products-grid.component.html',
  styleUrls: ['./products-grid.component.scss']
})
export class ProductsGridComponent implements OnInit, OnChanges, OnDestroy {

  @Input() catalog;
  @Input() loading;
  catalogs;
  loadingCatalog;
  seleccionado;
  currentCategory;
  categories;
  loadingAnimationConfig;
  getCatalogSubscription: Subscription;
  primaryColor;
  companyTitle;
  companyDescription;
  

  constructor(private dialogService: DialogService,
              private storageService: StorageService,
              private catalogsService: CatalogsService,
              private cd: ChangeDetectorRef,
              private activatedRoute: ActivatedRoute,


              ) { }

  ngOnInit() {
    this.catalog=  this.storageService.setUrlContext(this.activatedRoute.snapshot.paramMap.get('catalog'));
    this.setLoadingAnimationConfig();
    this.primaryColor = this.storageService.getMCatalogPrimaryColor();

    this.catalogsService
      .getCatalogs()
      .pipe(
        take(1),
        tap((catalogs) => (this.catalogs = catalogs)),
        tap((catalogs) => this.cd.detectChanges()),
        tap((catalogs) =>
          this.catalogsService.selectCatalog(this.catalogs[0].internalId)
        )
      )
      .subscribe();

      this.getCategories();

  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.catalog && changes.catalog.currentValue && !changes.catalog.previousValue) {
      this.categories = this.getCategories();
      this.currentCategory = null;
    } else if (changes.catalog && changes.catalog.currentValue && changes.catalog.previousValue) {
      if (changes.catalog.currentValue.InernalId !== changes.catalog.previousValue.InternalId) {
        this.categories = this.getCategories();
        this.currentCategory = null;
      }
    }
  }

  public openProductModal(product) {
    const dialog = this.dialogService.open(ProductDialogComponent, { data: { product, catalog: this.catalog }, modal: true });
    dialog.afterClosed.pipe().subscribe();
  }

  public verSelecionado(seleccionado){
    this.selectCategory(seleccionado)
  }

  public selectCategory(category) {
    console.log(category)
    this.currentCategory === category ? this.currentCategory = null : this.currentCategory = category;
  }

  private setLoadingAnimationConfig() {
    this.loadingAnimationConfig = {
      path: 'assets/animations/loading.animation.json',
      renderer: 'canvas',
      autoplay: true,
      loop: true
    };
  }

  private getCategories() {
    if (this.catalog) {
      const categories = [];
      this.catalog.products.forEach(p => {
        if (!categories.includes(p.Category)) {
          categories.push(p.Category);
        }
      });
      return categories;
    }
  }

  ngOnDestroy() {
    if (this.getCatalogSubscription) { this.getCatalogSubscription.unsubscribe(); }
  }
}
