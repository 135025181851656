import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PaymentDaoService {

  private BASE_URL = environment.apiURL;
  private OPTIONS = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json; charset=UTF-8'
    })
  };

  constructor(private http: HttpClient) { }

  public getPayments(context, lang) {
    const options = {};
    Object.assign(options, this.OPTIONS);
    options['headers'] = options['headers'].append('context', context);
    options['headers'] = options['headers'].append('lang', lang);
    return this.http.get(this.BASE_URL + 'api/v2.0/catalog/paymentGateways', options);
  }

  public getPaymentAuthorizationData(context, lang, paymentConfigInternalId, orderInternalId) {
    const options = {};
    Object.assign(options, this.OPTIONS);
    options['headers'] = options['headers'].append('context', context);
    options['headers'] = options['headers'].append('lang', lang);
    options['params'] = { paymentConfigInternalId, orderInternalId };
    return this.http.get(this.BASE_URL + 'api/v2.0/orders/authorizationProperties', options);
  }

  public confirmPayment(context, lang, confirmUrl, orderInternalId, resultCode, bankTransaction) {
    const options = {};
    Object.assign(options, this.OPTIONS);
    options['headers'] = options['headers'].append('context', context);
    options['headers'] = options['headers'].append('lang', lang);
    const item = { orderInternalId, resultCode, bankTransaction };
    return this.http.post(confirmUrl, item, options);
  }
}
