import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LayoutComponent } from './components/layout/layout.component';
import { OauthLibModule } from 'oauth-lib';
import { SocketsLibModule } from 'sockets-lib';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { OauthInterceptorService } from './services/oauth-interceptor/oauth-interceptor.service';
import { WelcomeComponent } from './components/welcome/welcome.component';
import { CatalogsComponent } from './components/catalogs/catalogs.component';
import { CatalogCardComponent } from './components/catalog-card/catalog-card.component';
import { ProductCardComponent } from './components/product-card/product-card.component';
import { CatalogsCarouselComponent } from './components/catalogs-carousel/catalogs-carousel.component';
import { ProductsGridComponent } from './components/products-grid/products-grid.component';
import { LottieAnimationViewModule } from 'ng-lottie';
import { DialogRef } from './components/dialog/dialog-ref';
import { DialogComponent } from './components/dialog/dialog.component';
import { ProductDialogComponent } from './components/product-dialog/product-dialog.component';
import { InsertionDirective } from './components/dialog/insertion.directive';
import { AddQtyComponent } from './components/add-qty/add-qty.component';
import { AddPreparationComponent } from './components/add-preparation/add-preparation.component';
import { PricePipe } from './pipes/price.pipe';
import { OrderComponent } from './components/order/order.component';
import { OrderItemComponent } from './components/order-item/order-item.component';
import { ConfirmationComponent } from './components/confirmation/confirmation.component';
import { InactivityDialogComponent } from './components/inactivity-dialog/inactivity-dialog.component';
import { DeleteProductDialogComponent } from './components/delete-product-dialog/delete-product-dialog.component';
import { CancelOrderDialogComponent } from './components/cancel-order-dialog/cancel-order-dialog.component';
import { RegisteredComponent } from './components/registered/registered.component';
import { PaymentComponent } from './components/payment/payment.component';
import { PaymentCardComponent } from './components/payment-card/payment-card.component';
import { PrintingComponent } from './components/printing/printing.component';
import { SmsDialogComponent } from './components/sms-dialog/sms-dialog.component';
import { NumericPadComponent } from './components/numeric-pad/numeric-pad.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LegalTermsDialogComponent } from './components/legal-terms-dialog/legal-terms-dialog.component';
import { TypeComponent } from './components/type/type.component';
import { FilterByCategoryPipe } from './pipes/filter-by-category.pipe';
import { ServiceWorkerModule } from '@angular/service-worker';
import { ScannedDialogComponent } from './components/scanned-dialog/scanned-dialog.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { WaitingDataphoneComponent } from './components/waiting-dataphone/waiting-dataphone.component';
import { PaymentErrorComponent } from './components/payment-error/payment-error.component';
import { WaitingPayComponent } from './components/waiting-mpay/waiting-mpay.component';
import { QRCodeModule } from 'angular2-qrcode';
import { NavbarComponent } from './components/navbar/navbar.component';
import { FooterComponent } from './components/footer/footer.component';
import { CatalogMenuComponent } from './components/catalog-menu/catalog-menu.component';
import { CatalogCardNavComponent } from './components/catalog-card-nav/catalog-card-nav.component';
import { ProductsComponent } from './components/products/products.component';
import { TypeCardComponent } from './components/type-card/type-card.component';
import { AddQtyOrderComponent } from './components/add-qty-order/add-qty-order.component';
import { UserDataComponent } from './components/user-data/user-data.component';
import { SearchComponent } from './components/search/search.component';
import { RedirectComponent } from './components/redirect/redirect.component';

@NgModule({
  declarations: [
    AppComponent,
    LayoutComponent,
    WelcomeComponent,
    CatalogsComponent,
    CatalogCardComponent,
    ProductCardComponent,
    CatalogsCarouselComponent,
    ProductsGridComponent,
    DialogComponent,
    ProductDialogComponent,
    ScannedDialogComponent,
    InsertionDirective,
    AddQtyComponent,
    AddPreparationComponent,
    PricePipe,
    OrderComponent,
    OrderItemComponent,
    ConfirmationComponent,
    InactivityDialogComponent,
    DeleteProductDialogComponent,
    CancelOrderDialogComponent,
    RegisteredComponent,
    PaymentComponent,
    PaymentCardComponent,
    WaitingDataphoneComponent,
    WaitingPayComponent,
    PrintingComponent,
    SmsDialogComponent,
    NumericPadComponent,
    LegalTermsDialogComponent,
    TypeComponent,
    FilterByCategoryPipe,
    PaymentErrorComponent,
    NavbarComponent,
    FooterComponent,
    CatalogMenuComponent,
    CatalogCardNavComponent,
    ProductsComponent,
    TypeCardComponent,
    AddQtyOrderComponent,
    UserDataComponent,
    SearchComponent,
    RedirectComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    OauthLibModule,
    SocketsLibModule,
    LottieAnimationViewModule.forRoot(),
    QRCodeModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
    }
    }),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: true, registrationStrategy: 'registerImmediately' })
  ],
  providers: [
    DialogRef,
    PricePipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: OauthInterceptorService,
      multi: true
    }
  ],
  entryComponents: [
    DialogComponent,
    ProductDialogComponent,
    InactivityDialogComponent,
    DeleteProductDialogComponent,
    CancelOrderDialogComponent,
    SmsDialogComponent,
    LegalTermsDialogComponent,
    ScannedDialogComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
