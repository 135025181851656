import { Component, OnInit, ChangeDetectorRef, Input, SimpleChanges } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { CatalogsService } from "src/app/services/catalogs-services/catalogs.service";
import { tap } from 'rxjs/internal/operators/tap';
import { take } from 'rxjs/internal/operators/take';
import { StorageService } from 'src/app/services/storage-service/storage.service';

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"],
})
export class NavbarComponent implements OnInit {
  @Input() title;
  @Input() image;
  @Input() selected;
  @Input() backgroundColor;
  @Input() textColor;

  public active: boolean = false;
  catalogs;
  catalog
  loadingCatalog;
  banner;
  center;
  brouse;

  constructor(
    private catalogsService: CatalogsService,
    private storageService: StorageService,
    private cd: ChangeDetectorRef,
    private activatedRoute: ActivatedRoute

  ) {}

  ngOnInit() {
    this.catalogsService.getCatalogs().pipe(
      tap(catalogs => this.catalogs = catalogs),
      ).subscribe();
      this.banner = this.storageService.getMCatalogBanner();
      this.center = this.storageService.getCenter();

      if(this.activatedRoute.snapshot.paramMap.get("brouse") == "catalogs"){
        this.brouse = "catalogs";
      }else{
        this.brouse = "products";
      }

  }

  ngOnChanges(changes: SimpleChanges) {
    this.catalogsService.getCatalogs().pipe(
      tap(catalogs => this.catalogs = catalogs),
      ).subscribe();
      console.log();
  }

  setActive(): void {
    this.active = !this.active;
  }

  private getCatalogs() {
    this.catalogsService.getCatalogs().pipe(
      take(1),
      tap(o => this.catalogs = o),
    ).subscribe();
  }

  private getCatalog(internalId) {
    this.catalogsService.getCatalog(internalId).pipe(
      take(1),
      tap(o => this.loadingCatalog = false),
      tap(o => this.catalog = o),
    ).subscribe();
  }
  private getCategories() {
    if (this.catalog) {
      const categories = [];
      this.catalog.products.forEach((p) => {
        //no existe
        if (!categories.includes(p.Category)) {
          categories.push(p.Category);
        }
      });
      return categories;
    }
  }

}


