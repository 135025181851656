import { Component, OnInit, HostListener, OnDestroy } from '@angular/core';
import { DialogOptions } from '../dialog/dialog.model';
import { DialogRef } from '../dialog/dialog-ref';
import { InactivityService } from 'src/app/services/inactivity-service/inactivity.service';
import { StorageService } from 'src/app/services/storage-service/storage.service';
import { tap } from 'rxjs/internal/operators';
import { Subscription, timer } from 'rxjs';

@Component({
  selector: 'app-scanned-dialog',
  templateUrl: './scanned-dialog.component.html',
  styleUrls: ['./scanned-dialog.component.scss']
})
export class ScannedDialogComponent implements OnInit, OnDestroy {

  addedLottieConfig;
  errorLottieConfig;
  primaryColor;
  tSubscription: Subscription;

  constructor(public data: DialogOptions,
              private storageService: StorageService,
              private inactivityService: InactivityService,
              public dialogRef: DialogRef) {}

  ngOnInit() {
    this.setAddedLottieAnimation();
    this.setErrorLottieAnimation();
    this.primaryColor = this.storageService.getMCatalogPrimaryColor();
    const t = timer(3000);
    this.tSubscription = t.pipe(
      tap(o => this.dialogRef.close())
    ).subscribe();
  }

  @HostListener('click', ['$event'])
  public click(e) {
    // this.inactivityService.reloadInactivityTimer();
  }

  private setAddedLottieAnimation() {
    this.addedLottieConfig = {
      path: 'assets/animations/confirmation.animation.json',
      renderer: 'canvas',
      autoplay: true,
      loop: true
    };
  }

  private setErrorLottieAnimation() {
    this.errorLottieConfig = {
      path: 'assets/animations/error.animation.json',
      renderer: 'canvas',
      autoplay: true,
      loop: true
    };
  }

  ngOnDestroy() {
    if (this.tSubscription) { this.tSubscription.unsubscribe(); }
  }
}
