import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { flatMap } from 'rxjs/internal/operators';
import { tap } from 'rxjs/internal/operators/tap';
import { InactivityService } from 'src/app/services/inactivity-service/inactivity.service';
import { PaymentService } from 'src/app/services/payments-services/payment.service';
import { StorageService } from 'src/app/services/storage-service/storage.service';

@Component({
  selector: 'app-waiting-dataphone',
  templateUrl: './waiting-dataphone.component.html',
  styleUrls: ['./waiting-dataphone.component.scss']
})
export class WaitingDataphoneComponent implements OnInit {

  dataphoneConfig;
  errorConfig;
  center;
  banner;
  status;
  primaryColor;

  constructor(private storageService: StorageService,
              private paymentService: PaymentService,
              private inactivityService: InactivityService,
              private router: Router,
              private activatedRoute: ActivatedRoute) { }

  @HostListener('window:message', ['$event']) onPostMessage(event) {
    let data = null;
    try {
      data = JSON.parse(event['data']);
      if (data && data['paymentStatus'] && data['paymentStatus']['status'] === 'SUCCESS') {
        this.storageService.setBankTransaction(data['paymentStatus']['details']);
        this.paymentService.getPaymentAuthorizationData(this.activatedRoute.snapshot.params['gatewayInternalId']).pipe(
          flatMap(o => this.paymentService.confirmPayment(o['urlNotification'], '0', this.storageService.getBankTransaction())),
          tap(o => this.router.navigate(['/registered/printing']))
        ).subscribe();
      } else if (data && data['paymentStatus'] && data['paymentStatus']['status'] === 'ERROR') {
        this.status = `[${ data['paymentStatus']['status'] }] ${ data['paymentStatus']['details'] }`;
        this.storageService.setBankTransaction(data['paymentStatus']['details']);
        // this.inactivityService.reloadInactivityTimer();
      }
    } catch (e) {
      console.dir(e);
      this.status = JSON.stringify(e);
      // this.inactivityService.reloadInactivityTimer();
    }
  }

  ngOnInit() {
    this.center = this.storageService.getCenter();
    this.banner = this.storageService.getMCatalogBanner();
    this.primaryColor = this.storageService.getMCatalogPrimaryColor();
    this.setDataphoneAnimation();
    this.setErrorAnimation();
  }

  public retryPayment() {
    this.router.navigate(['/registered/layout/payment']);
  }

  private setDataphoneAnimation() {
    this.dataphoneConfig = {
      path: 'assets/animations/dataphone.animation.json',
      renderer: 'canvas',
      autoplay: true,
      loop: true
    };
  }

  private setErrorAnimation() {
    this.errorConfig = {
      path: 'assets/animations/error.animation.json',
      renderer: 'canvas',
      autoplay: true,
      loop: true
    };
  }

}
