import { Injectable, isDevMode } from '@angular/core';
import { map } from 'rxjs/internal/operators/map';
import { tap } from 'rxjs/internal/operators/tap';
import { OrderDaoService } from 'src/app/dao/order-dao-services/order-dao.service';
import { StorageService } from '../storage-service/storage.service';

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  orderId;

  constructor(private storageService: StorageService,
              private orderDaoService: OrderDaoService) { }

  public preConfirmOrder(articles, totalAmount, smsPhone) {
    return this.orderDaoService.confirmOrder(this.storageService.getContext(), this.storageService.getLang(),
    'M_CATALOG', this.storageService.getPayment(), this.storageService.getType(), null, this.storageService.getCustomerPhone(), this.storageService.getCustomerName(), this.storageService.getCustomerEmail(), articles, totalAmount).pipe(
      tap(o => !isDevMode() || console.dir(o)),
      tap(o => this.storageService.setOrderId(o['data']['order']['internalId'])),
      map(o => o['data']['order'])
    );
  }

  public getOrderStatus() {
    return this.orderDaoService.getOrderStatus(this.storageService.getContext(), this.storageService.getLang(), this.storageService.getOrderId()).pipe(
      tap(o => !isDevMode() || console.dir(o)),
      map(o => o['data']['order']['status'])
    );
  }

}
