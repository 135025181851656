import { Component, OnInit, OnDestroy } from '@angular/core';
import { tap } from 'rxjs/internal/operators/tap';
import { interval } from 'rxjs/internal/observable/interval';
import { Subscription } from 'rxjs/internal/Subscription';
import { DialogRef } from '../dialog/dialog-ref';
import { Router } from '@angular/router';
import { StorageService } from 'src/app/services/storage-service/storage.service';

@Component({
  selector: 'app-inactivity-dialog',
  templateUrl: './inactivity-dialog.component.html',
  styleUrls: ['./inactivity-dialog.component.scss']
})
export class InactivityDialogComponent implements OnInit, OnDestroy {

  primaryColor;
  countDown;
  confirmIntervalSubscription: Subscription;

  constructor(private dialog: DialogRef,
              private storageService: StorageService,
              private router: Router) {
                this.countDown = 30;
              }

  ngOnInit() {
    this.primaryColor = this.storageService.getMCatalogPrimaryColor();
    this.initConfirmTimer();
  }

  public initConfirmTimer() {
    if (this.confirmIntervalSubscription) { this.confirmIntervalSubscription.unsubscribe(); }
    const confirmInterval = interval(1000);
    this.confirmIntervalSubscription = confirmInterval.pipe(
      tap(o => this.countDown > 1 ? this.countDown -= 1 : this.reset())
    ).subscribe();
  }

  public reset() {
    if (this.confirmIntervalSubscription) { this.confirmIntervalSubscription.unsubscribe(); }
    this.dialog.close(false);
    this.router.navigate(['/registered/welcome']);
  }

  public stillHere() {
    this.dialog.close(true);
    if (this.confirmIntervalSubscription) { this.confirmIntervalSubscription.unsubscribe(); }
  }

  ngOnDestroy() {
    if (this.confirmIntervalSubscription) { this.confirmIntervalSubscription.unsubscribe(); }
  }
}
