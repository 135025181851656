import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common'
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators  } from '@angular/forms';
import { StorageService } from '../../services/storage-service/storage.service';
import { CartService } from 'src/app/services/cart-services/cart.service';


@Component({
  selector: 'app-user-data',
  templateUrl: './user-data.component.html',
  styleUrls: ['./user-data.component.scss']
})
export class UserDataComponent implements OnInit {

  UserDataForm = new FormGroup({
    username: new FormControl('',[Validators.required, Validators.minLength(3)]),
    phone: new FormControl('',[Validators.required, Validators.pattern("^(\\+34|0034|34)?[6789]\\d{8}$")]),
    email: new FormControl('',[Validators.required, Validators.email]),
  });
  order;
  total;
  validator;

  constructor(
    private location: Location,
    private storageService: StorageService,
    private router: Router,
    private cartService: CartService,
    ) { }

    onSubmit() {
      // console.warn(this.UserDataForm.value); 
      // console.log(this.UserDataForm.controls['username'].value)
      //en this.profileForm.value tenemos el valor del form para poder manipularlo a nuestro gusto.Si queremos acceder a, por ejemplo, un control especifico, podemos hacerlo con this.profileForm.controls['nombreControl']
      this.storageService.setCustomerName(this.UserDataForm.controls['username'].value);
      this.storageService.setCustomerPhone(this.UserDataForm.controls['phone'].value);
      this.storageService.setCustomerEmail(this.UserDataForm.controls['email'].value);
      this.goPay();
    }


  ngOnInit() {
    this.total = this.cartService.getCartTotal();
    this.order = this.cartService.getCart();
    this.validator = false;

    console.log("El nombre es -> " + this.storageService.getCustomerName())
    if(this.storageService.getCustomerName()){
    this.UserDataForm.controls['username'].setValue(this.storageService.getCustomerName());
    this.UserDataForm.controls['phone'].setValue(this.storageService.getCustomerPhone());
    this.UserDataForm.controls['email'].setValue(this.storageService.getCustomerEmail());
    }

  }

  validatorChange(){
    console.log("HOLA")
    this.validator = true
  }

  public cancel(): void {
    this.location.back()  
  }

  hola(){

  }

  goPay(){
    this.router.navigate(['/registered/layout/payment']);
  }

}
