import { Component, OnInit, Input, OnDestroy, SimpleChanges, Output } from "@angular/core";
import { Location } from "@angular/common";
import { CartService } from "src/app/services/cart-services/cart.service";
import { StorageService } from "src/app/services/storage-service/storage.service";
import { Router } from "@angular/router";
import { take, tap } from "rxjs/internal/operators";
import { Subscription } from "rxjs";
import { DialogService } from "../dialog/dialog.service";
import { CancelOrderDialogComponent } from "../cancel-order-dialog/cancel-order-dialog.component";
import { TranslateService } from "@ngx-translate/core";
import { RfidService } from "src/app/services/rfid-services/rfid.service";
import { MhelpService } from "src/app/services/mhelp-services/mhelp.service";

@Component({
  selector: "app-order",
  templateUrl: "./order.component.html",
  styleUrls: ["./order.component.scss"],
})
export class OrderComponent implements OnInit, OnDestroy {
  center;
  banner;
  primaryColor;
  secondaryColor;
  assistanceActive;
  order;
  total;
  lottieConfig;
  listenCartChangesSubscription: Subscription;
  listenChangesSubscription: Subscription;
  cant;

  arrayLength;

  constructor(
    private cartService: CartService,
    private router: Router,
    private dialogService: DialogService,
    private translateService: TranslateService,
    private rfidService: RfidService,
    private mhelpService: MhelpService,
    private storageService: StorageService,
    private location: Location
  ) {
    this.setLottieAnimation();
  }

  ngOnInit() {
    this.center = this.storageService.getCenter();
    this.banner = this.storageService.getMCatalogBanner();
    this.primaryColor = this.storageService.getMCatalogPrimaryColor();
    this.secondaryColor = this.storageService.getMCatalogSecondaryColor();
    this.assistanceActive = this.storageService.getMCatalogAssistanceActive();
    this.order = this.cartService.getCart();
    this.total = this.cartService.getCartTotal();
    this.rfidService.switchOnRfid();

    if (this.listenCartChangesSubscription) {
      this.listenCartChangesSubscription.unsubscribe();
    }
     
    this.listenCartChangesSubscription = this.cartService
      .listenChanges()
      .pipe(
        tap((o) => (this.order = this.cartService.getCart())),
        tap((o) => (this.total = this.cartService.getCartTotal())),
      )
      .subscribe();

    // console.log("this.order = this.cartService.getCart()");
    // this.arrayLength = this.order.length;
    // console.log(this.order);
    // console.log(this.arrayLength-1);
    // console.log((this.order[this.arrayLength-1].data.qty));
    this.listenCartChangesRefresh();

  }

  public cancel(): void {
    this.location.back();
  }

  public confirmOrder() {
    this.router.navigate(["/registered/layout/type"]);
  }

  // public setLang(lang) {
  //   this.translateService.use(lang);
  //   this.storageService.setLang(lang);
  // }

  public delete(item) {
    this.cartService.deleteItem(item.id);
  }

  public gotoCatalogs() {
    this.router.navigate(["/registered/layout/catalogs", "catalogs"]);
  }

  private setLottieAnimation() {
    this.lottieConfig = {
      path: "assets/animations/emptyorder.animation.json",
      renderer: "canvas",
      autoplay: true,
      loop: true,
    };
  }

  private listenCartChangesRefresh() {
    this.listenCartChangesSubscription = this.cartService
    .listenChanges()
    .pipe(
      tap((o) => (this.order = this.cartService.getCart())),
      tap((o) => (this.total = this.cartService.getCartTotal()))
    )
    .subscribe();
    this.listenCartChangesSubscription;
  }

  public mHelp() {
    this.mhelpService.openmHelp();
  }

  ngOnDestroy() {
    this.rfidService.switchOffRfid();
    if (this.listenCartChangesSubscription) {
      this.listenCartChangesSubscription.unsubscribe();
    }
  }

}
