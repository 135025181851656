import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { StorageService } from '../storage-service/storage.service';
import { SecurityService } from '../security-service/security.service';
import { HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { throwError } from 'rxjs/internal/observable/throwError';
import { filter } from 'rxjs/internal/operators/filter';
import { take } from 'rxjs/internal/operators/take';
import { switchMap } from 'rxjs/internal/operators/switchMap';
import { OauthTokenData } from 'oauth-lib';
import { catchError } from 'rxjs/internal/operators';

@Injectable({
  providedIn: 'root'
})
export class OauthInterceptorService {

  private refreshingToken = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(private storageService: StorageService,
              private securityService: SecurityService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(this.addOAuthHeader(req)).pipe(
      catchError(
        (error) => {
          if (this.isExcluded(req)) {
            return throwError(error);
          }

          if (error.status !== 401) {
            return throwError(error);
          }

          if (this.refreshingToken) {
            return this.refreshTokenSubject.pipe(
              filter(res => res !== null),
              take(1),
              switchMap(() =>  next.handle(this.addOAuthHeader(req)))
            );
          } else {
            this.refreshingToken = true;
            this.refreshTokenSubject.next(null);
            return this.securityService.requestOAuthToken().pipe(
              switchMap((token: OauthTokenData) => {
                this.refreshingToken = false;
                this.storageService.setOAuthToken(token.access_token);
                this.refreshTokenSubject.next(token);
                return next.handle(this.addOAuthHeader(req));
              }),
              catchError((err) => {
                this.refreshingToken = false;
                return throwError(error);
              })
            );
          }
        }
      )
    );
  }

  private addOAuthHeader(req) {
    if (this.isExcluded(req)) {
      return req;
    }
    if (!this.storageService.getOAuthToken()) {
      this.storageService.setOAuthToken(' ');
    }
    return req.clone({ headers: req.headers.set('Authorization', 'Bearer ' + this.storageService.getOAuthToken()) });
  }

  private isExcluded(req: HttpRequest<any>) {
    const oauthTokenRequest = new RegExp('oauth/token');
    const authorizeRequest = new RegExp('authorize/access_token');
    const blobCoreWindows = new RegExp('storagembaseprod.blob.core.windows.net');
    if (oauthTokenRequest.test(req.url)  || authorizeRequest.test(req.url) || blobCoreWindows.test(req.url)) {
      return true;
    }
    return false;
  }

}
