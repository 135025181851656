import { Injectable, isDevMode } from '@angular/core';
import { tap } from 'rxjs/internal/operators/tap';
import { map } from 'rxjs/internal/operators/map';
import { PaymentDaoService } from 'src/app/dao/payments-dao-services/payment-dao.service';
import { StorageService } from '../storage-service/storage.service';
import { Payment } from 'src/app/models/payment.model';
import { PaymentGateway } from 'src/app/models/payment-gateway.model';
import { PaymentType } from 'src/app/models/payment-type.model';
import { PaymentPeripheral } from 'src/app/models/payment-peripheral.model';

declare const Android;

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  constructor(private paymentDaoService: PaymentDaoService,
              private storageService: StorageService) { }

  public getSavedPayments(): Array<Payment> {
    return this.parsePayments(this.storageService.getPayments());
  }

  public getPayments() {
    return this.paymentDaoService.getPayments(this.storageService.getContext(), this.storageService.getLang()).pipe(
      tap(o => !isDevMode() || console.dir(o)),
      map(o => {
        const paymentConfigs = new Array<Payment>();
        o['data']['paymentConfigs'].forEach(pc => {
          if (pc['type']['code'] === 'MPAY') {
            pc['image'] = 'assets/icons/mpay_logo.png';
          }
          const paymentConfig = new Payment();
          Object.assign(paymentConfig, pc);

          const paymentGateway = new PaymentGateway();
          Object.assign(paymentGateway, pc['gateway']);
          paymentConfig.Gateway = paymentGateway;

          const paymentType = new PaymentType();
          Object.assign(paymentType, pc['type']);
          paymentConfig.Type = paymentType;

          const dataphonePeripherals = new Array<PaymentPeripheral>();
          if (pc['dataphonePeripherals']) {
            pc['dataphonePeripherals'].forEach(dp => {
              const dataphonePeripheral = new PaymentPeripheral();
              Object.assign(dataphonePeripheral, dp);
              dataphonePeripherals.push(dataphonePeripheral);
            });
          }
          paymentConfig.DataphonePeripherals = dataphonePeripherals;
          paymentConfigs.push(paymentConfig);
        });
        this.storageService.setPayments(paymentConfigs);
        return paymentConfigs;
      })
    );
  }

  public getPaymentAuthorizationData(paymentConfigInternalId) {
    return this.paymentDaoService.getPaymentAuthorizationData(this.storageService.getContext(), this.storageService.getLang(), paymentConfigInternalId, this.storageService.getOrderId()).pipe(
      tap(o => !isDevMode() || console.dir(o)),
      map(o => o['data']['properties']['properties'] ? JSON.parse(o['data']['properties']['properties']) : o['data']['properties'])
    );
  }

  public confirmPayment(confirmUrl, resultCode, bankTransaction) {
    return this.paymentDaoService.confirmPayment(this.storageService.getContext(), this.storageService.getLang(), confirmUrl, this.storageService.getOrderId(), resultCode, bankTransaction).pipe(
      map(o => o)
    );
  }

  public sendPayToDataphone(dataphone, amount) {
    try {
      // tslint:disable-next-line:only-arrow-functions
      (function() { Android.pay(JSON.stringify({ paymentDetails: { amount }, gatewayDetails: dataphone })); })();
    } catch (e) {
      console.dir(e);
    }
  }

  public parsePayments(unparsedPayments) {
    const paymentConfigs = new Array<Payment>();
    unparsedPayments.forEach(pc => {
      const paymentConfig = new Payment();
      Object.assign(paymentConfig, pc);

      const paymentGateway = new PaymentGateway();
      Object.assign(paymentGateway, pc['gateway']);
      paymentConfig.Gateway = paymentGateway;

      const paymentType = new PaymentType();
      Object.assign(paymentType, pc['type']);
      paymentConfig.Type = paymentType;

      const dataphonePeripherals = new Array<PaymentPeripheral>();
      if (pc['dataphonePeripherals']) {
        pc['dataphonePeripherals'].forEach(dp => {
          const dataphonePeripheral = new PaymentPeripheral();
          Object.assign(dataphonePeripheral, dp);
          dataphonePeripherals.push(dataphonePeripheral);
        });
      }
      paymentConfig.DataphonePeripherals = dataphonePeripherals;
      paymentConfigs.push(paymentConfig);
    });
    return paymentConfigs;
  }
}
