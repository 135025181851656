import { Product } from './product.model';
import { Desk } from './desk.model';

export class Catalog {

    private description: string;
    private internalId: string;
    private backgroundColor: string;
    private textColor: string;
    private products: Product[];
    private turnDesks: Desk[];

    constructor(description?: string, internalId?: string, backgroundColor?: string, textColor?: string, products?: Product[], turnDesks?: Desk[]) {
        this.description = description;
        this.internalId = internalId;
        this.backgroundColor = backgroundColor;
        this.textColor = textColor;
        this.products = products;
        this.turnDesks = turnDesks;
    }

    public get Description() {
        return this.description;
    }

    public set Description(description) {
        this.description = description;
    }

    public get InternalId() {
        return this.internalId;
    }

    public set InternalId(internalId) {
        this.internalId = internalId;
    }

    public set BackgroundColor(backgroundColor) {
        this.backgroundColor = backgroundColor;
    }

    public get BackgroundColor() {
        return this.backgroundColor;
    }

    public set TextColor(textColor) {
        this.textColor = textColor;
    }

    public get TextColor() {
        return this.textColor;
    }

    public get Products() {
        return this.products;
    }

    public set Products(products) {
        this.products = products;
    }

    public get TurnDesks() {
        return this.turnDesks;
    }

    public set TurnDesks(turnDesks) {
        this.turnDesks = turnDesks;
    }
}
