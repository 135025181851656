import { Component, HostListener, OnInit } from '@angular/core';
import { InactivityService } from 'src/app/services/inactivity-service/inactivity.service';
import { DialogRef } from '../dialog/dialog-ref';

@Component({
  selector: 'app-sms-dialog',
  templateUrl: './sms-dialog.component.html',
  styleUrls: ['./sms-dialog.component.scss']
})
export class SmsDialogComponent implements OnInit {

  constructor(private dialog: DialogRef,
              private inactivityService: InactivityService) { }

  ngOnInit() {
  }

  @HostListener('click', ['$event'])
  public click(e) {
    // this.inactivityService.reloadInactivityTimer();
  }

  public accept(e) {
    this.dialog.close(e);
  }

  public cancel() {
    this.dialog.close(false);
  }

  public withoutPhoneNumber() {
    this.dialog.close(true);
  }

  public openLegalTerms() {
    this.dialog.close('openlegalterms');
  }
}
