export const environment = {
  envName: 'azuredev',
  hmr: false,
  production: false,
  serviceWorker: true,
  apiURL: 'https://back.devarea.app/mBase/',
  oauth: {
    host: 'back.devarea.app',
    https: true,
    client_id: 'mbasetv',
    client_secret: 'Su4r3zD03ntW0rk'
  }
};
