import { Component, OnInit, HostListener } from '@angular/core';
import { InactivityService } from 'src/app/services/inactivity-service/inactivity.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {

  constructor(private inactivityService: InactivityService) { }

  ngOnInit() {
    // this.inactivityService.reloadInactivityTimer();
  }

  @HostListener('click', ['$event'])
  public click(e) {
    // this.inactivityService.reloadInactivityTimer();
  }

}
