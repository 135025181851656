export const COUNTRY_PREFIXES = {
     '+1': 'US' ,
     '+1403': 'CA' ,
     '+1587': 'CA' ,
     '+1780': 'CA' ,
     '+1825': 'CA' ,
     '+1236': 'CA' ,
     '+1250': 'CA' ,
     '+1604': 'CA' ,
     '+1778': 'CA' ,
     '+1204': 'CA' ,
     '+1431': 'CA' ,
     '+1506': 'CA' ,
     '+1709': 'CA' ,
     '+1902': 'CA' ,
     '+1782': 'CA' ,
     '+1226': 'CA' ,
     '+1249': 'CA' ,
     '+1289': 'CA' ,
     '+1343': 'CA' ,
     '+1365': 'CA' ,
     '+1416': 'CA' ,
     '+1437': 'CA' ,
     '+1519': 'CA' ,
     '+1548': 'CA' ,
     '+1613': 'CA' ,
     '+1647': 'CA' ,
     '+1705': 'CA' ,
     '+1807': 'CA' ,
     '+1905': 'CA' ,
     '+1418': 'CA' ,
     '+1438': 'CA' ,
     '+1450': 'CA' ,
     '+1514': 'CA' ,
     '+1579': 'CA' ,
     '+1581': 'CA' ,
     '+1819': 'CA' ,
     '+1873': 'CA' ,
     '+1306': 'CA' ,
     '+1639': 'CA' ,
     '+1867': 'CA' ,
     '+93': 'AF' ,
     '+355': 'AL' ,
     '+213': 'DZ' ,
     '+1684': 'AS' ,
     '+376': 'AD' ,
     '+244': 'AO' ,
     '+1264': 'AI' ,
     '+672': 'AQ' ,
     '+1268': 'AG' ,
     '+54': 'AR' ,
     '+374': 'AM' ,
     '+297': 'AW' ,
     '+61': 'AU' ,
     '+6189164': 'CX' ,
     '+6189162': 'CC' ,
     '+43': 'AT' ,
     '+994': 'AZ' ,
     '+1242': 'BS' ,
     '+973': 'BH' ,
     '+880': 'BD' ,
     '+1246': 'BB' ,
     '+375': 'BY' ,
     '+32': 'BE' ,
     '+501': 'BZ' ,
     '+229': 'BJ' ,
     '+1441': 'BM' ,
     '+975': 'BT' ,
     '+591': 'BO' ,
     '+387': 'BA' ,
     '+267': 'BW' ,
     '+55': 'BR' ,
     '+246': 'IO' ,
     '+1284': 'VG' ,
     '+673': 'BN' ,
     '+359': 'BG' ,
     '+226': 'BF' ,
     '+257': 'BI' ,
     '+855': 'KH' ,
     '+237': 'CM' ,
     '+238': 'CV' ,
     '+1345': 'KY' ,
     '+236': 'CF' ,
     '+235': 'TD' ,
     '+56': 'CL' ,
     '+86': 'CN' ,
     '+57': 'CO' ,
     '+682': 'CK' ,
     '+506': 'CR' ,
     '+385': 'HR' ,
     '+53': 'CU' ,
     '+599': 'CW' ,
     '+357': 'CY' ,
     '+420': 'CZ' ,
     '+243': 'CD' ,
     '+45': 'DK' ,
     '+253': 'DJ' ,
     '+1767': 'DM' ,
     '+1809': 'DOM' ,
     '+1829': 'DOM' ,
     '+1849': 'DOM' ,
     '+670': 'TL' ,
     '+593': 'EC' ,
     '+20': 'EG' ,
     '+503': 'SV' ,
     '+240': 'GQ' ,
     '+291': 'ER' ,
     '+372': 'EE' ,
     '+251': 'ET' ,
     '+500': 'FK' ,
     '+298': 'FO' ,
     '+679': 'FJ' ,
     '+358': 'FI' ,
     '+33': 'FR' ,
     '+689': 'PF' ,
     '+241': 'GA' ,
     '+220': 'GM' ,
     '+995': 'GE' ,
     '+49': 'DE' ,
     '+233': 'GH' ,
     '+350': 'GI' ,
     '+30': 'GR' ,
     '+299': 'GL' ,
     '+1473': 'GD' ,
     '+1671': 'GU' ,
     '+502': 'GT' ,
     '+441481': 'GG' ,
     '+224': 'GN' ,
     '+245': 'GW' ,
     '+592': 'GY' ,
     '+509': 'HT' ,
     '+504': 'HN' ,
     '+852': 'HK' ,
     '+36': 'HU' ,
     '+354': 'IS' ,
     '+91': 'IN' ,
     '+62': 'ID' ,
     '+98': 'IR' ,
     '+964': 'IQ' ,
     '+353': 'IE' ,
     '+441624': 'IM' ,
     '+972': 'IL' ,
     '+39': 'IT' ,
     '+225': 'CI' ,
     '+1876': 'JM' ,
     '+81': 'JP' ,
     '+441534': 'JE' ,
     '+962': 'JO' ,
     '+7': 'RU' ,
     '+76': 'KZ' ,
     '+77': 'KZ' ,
     '+254': 'KE' ,
     '+686': 'KI' ,
     '+383': 'XK' ,
     '+965': 'KW' ,
     '+996': 'KG' ,
     '+856': 'LA' ,
     '+371': 'LV' ,
     '+961': 'LB' ,
     '+266': 'LS' ,
     '+231': 'LR' ,
     '+218': 'LY' ,
     '+423': 'LI' ,
     '+370': 'LT' ,
     '+352': 'LU' ,
     '+853': 'MO' ,
     '+389': 'MK' ,
     '+261': 'MG' ,
     '+265': 'MW' ,
     '+60': 'MY' ,
     '+960': 'MV' ,
     '+223': 'ML' ,
     '+356': 'MT' ,
     '+692': 'MH' ,
     '+222': 'MR' ,
     '+230': 'MU' ,
     '+262': 'RE' ,
     '+269': 'KM' ,
     '+52': 'MX' ,
     '+691': 'FM' ,
     '+373': 'MD' ,
     '+377': 'MC' ,
     '+976': 'MN' ,
     '+382': 'ME' ,
     '+1664': 'MS' ,
     '+212': 'MA' ,
     '+212528': 'EH' ,
     '+258': 'MZ' ,
     '+95': 'MM' ,
     '+264': 'NA' ,
     '+674': 'NR' ,
     '+977': 'NP' ,
     '+31': 'NL' ,
     '+687': 'NC' ,
     '+64': 'NZ' ,
     '+505': 'NI' ,
     '+227': 'NE' ,
     '+234': 'NG' ,
     '+683': 'NU' ,
     '+850': 'KP' ,
     '+1670': 'MP' ,
     '+47': 'NO' ,
     '+4732': 'SJ' ,
     '+968': 'OM' ,
     '+92': 'PK' ,
     '+680': 'PW' ,
     '+970': 'PS' ,
     '+507': 'PA' ,
     '+675': 'PG' ,
     '+595': 'PY' ,
     '+51': 'PE' ,
     '+63': 'PH' ,
     '+48': 'PL' ,
     '+351': 'PT' ,
     '+1787': 'PR' ,
     '+1939': 'PR' ,
     '+974': 'QA' ,
     '+242': 'CG' ,
     '+40': 'RO' ,
     '+250': 'RW' ,
     '+290': 'SH' ,
     '+1869': 'KN' ,
     '+1758': 'LC' ,
     '+508': 'PM' ,
     '+1784': 'VC' ,
     '+685': 'WS' ,
     '+378': 'SM' ,
     '+239': 'ST' ,
     '+966': 'SA' ,
     '+221': 'SN' ,
     '+381': 'RS' ,
     '+248': 'SC' ,
     '+232': 'SL' ,
     '+65': 'SG' ,
     '+1721': 'SX' ,
     '+421': 'SK' ,
     '+386': 'SI' ,
     '+677': 'SB' ,
     '+252': 'SO' ,
     '+27': 'ZA' ,
     '+82': 'KR' ,
     '+211': 'SS' ,
     '+34': 'ES' ,
     '+94': 'LK' ,
     '+249': 'SD' ,
     '+597': 'SR' ,
     '+268': 'SZ' ,
     '+46': 'SE' ,
     '+41': 'CH' ,
     '+963': 'SY' ,
     '+886': 'TW' ,
     '+992': 'TJ' ,
     '+255': 'TZ' ,
     '+66': 'TH' ,
     '+228': 'TG' ,
     '+690': 'TK' ,
     '+676': 'TO' ,
     '+1868': 'TT' ,
     '+216': 'TN' ,
     '+90': 'TR' ,
     '+993': 'TM' ,
     '+1649': 'TC' ,
     '+688': 'TV' ,
     '+1340': 'VI' ,
     '+256': 'UG' ,
     '+380': 'UA' ,
     '+971': 'AE' ,
     '+44': 'GB' ,
     '+598': 'UY' ,
     '+998': 'UZ' ,
     '+678': 'VU' ,
     '+379': 'VA' ,
     '+58': 'VE' ,
     '+84': 'VN' ,
     '+681': 'WF' ,
     '+967': 'YE' ,
     '+260': 'ZM' ,
     '+263': 'ZW'
};
