import { Component, OnInit, Input, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";
import { tap } from "rxjs/internal/operators/tap";
import { CatalogsService } from "src/app/services/catalogs-services/catalogs.service";
import { Router, ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-catalogs-carousel",
  templateUrl: "./catalogs-carousel.component.html",
  styleUrls: ["./catalogs-carousel.component.scss"],
})
export class CatalogsCarouselComponent implements OnInit, OnDestroy {
  @Input() catalogs;
  listenSelectCatalogSubscription: Subscription;

  constructor(
    private catalogsService: CatalogsService,
    private router: Router
  ) {}

  ngOnInit() {
    this.listenSelectCatalogSubscription = this.catalogsService
      .listenSelectCatalog()
      .pipe(
        tap((o) => this.selectCatalog(o))
        )
      .subscribe();
  }
    
  public selectCatalog(internalId) {
    this.catalogs.forEach((c) =>
      c.internalId === internalId ? (c.selected = true) : (c.selected = false)
    );
    this.catalogsService.refreshCatalog(internalId);
  }       
                           
  public gotoProducts(internalId){
    this.router.navigate(['./registered/layout/redirect' ,internalId.toString()]);
  }

  ngOnDestroy() {
    if (this.listenSelectCatalogSubscription) {
      this.listenSelectCatalogSubscription.unsubscribe();
    }
  }
}
