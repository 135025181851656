import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common'
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { take, tap } from 'rxjs/internal/operators';
import { CartService } from 'src/app/services/cart-services/cart.service';
import { CompanyService } from 'src/app/services/company-services/company.service';
import { MhelpService } from 'src/app/services/mhelp-services/mhelp.service';
import { StorageService } from 'src/app/services/storage-service/storage.service';
import { CancelOrderDialogComponent } from '../cancel-order-dialog/cancel-order-dialog.component';
import { DialogService } from '../dialog/dialog.service';
import { LegalTermsDialogComponent } from '../legal-terms-dialog/legal-terms-dialog.component';
import { SmsDialogComponent } from '../sms-dialog/sms-dialog.component';
import { Catalog } from 'src/app/models/catalog.model';

@Component({
  selector: 'app-type',
  templateUrl: './type.component.html',
  styleUrls: ['./type.component.scss']
})
export class TypeComponent implements OnInit {

  center;
  banner;
  primaryColor;
  assistanceActive;
  order;
  services;
  getMCatalogBannerSubscription: Subscription;
  total;
  type;
  active;
  
  constructor(private storageService: StorageService,
              private router: Router,
              private companyService: CompanyService,
              private dialogService: DialogService,
              private mhelpService: MhelpService,
              private translateService: TranslateService,
              private cartService: CartService,
              private location: Location) { }

  ngOnInit() {
    this.total = this.cartService.getCartTotal();
    this.center = this.storageService.getCenter();
    this.banner = this.storageService.getMCatalogBanner();
    this.primaryColor = this.storageService.getMCatalogPrimaryColor();
    this.assistanceActive = this.storageService.getMCatalogAssistanceActive();
    this.order = this.cartService.getCart();
    this.getMCatalogService();
  }


  public cancel(): void {
    this.location.back()  
  }

  public noSetType(){
    alert("Debe seleccionar una opcion")
  }

  public selectType(type,i){
      console.log(type);
      // console.log(i);
      this.active = i;
      // console.log(this.active);
      return this.type = type;
  }

  public setType() {
    // console.log(this.type)
    this.storageService.setType(this.type);
    if (this.someDeskHasSmsEnabled()) {
      // const dialog = this.dialogService.open(SmsDialogComponent, { modal: false });
      // dialog.afterClosed.pipe(
      //   take(1),
      //   tap(o => {
      //     if (o === 'openlegalterms') {
      //       this.openLegalterms();
      //     } else if (o === true) {
      //       this.router.navigate(['/registered/layout/userdata']);
      //     } else if (o) {
      //       this.storageService.setSmsPhone(o);
      //       this.router.navigate(['/registered/layout/userdata']);
      //     } else if (o === false && this.services.length === 1) {
      //       this.router.navigate(['/registered/layout/userdata']);
      //     }
      //   }),
      // ).subscribe();
      this.router.navigate(['/registered/layout/userdata']);

    } else {
      this.router.navigate(['/registered/layout/userdata']);
    }
  }

  // public setLang(lang) {
  //   this.translateService.use(lang);
  //   this.storageService.setLang(lang);
  // }

  // public getServiceThumbnail(service) {
  //   switch (service) {
  //     case 'EATIN':
  //       return 'assets/icons/cutlery.svg';
  //     case 'DELIVERY':
  //       return 'assets/icons/truck.svg';
  //     case 'TAKEAWAY':
  //       return 'assets/icons/eatbag.svg';
  //     case 'NOW':
  //       return 'assets/icons/bag.svg';
  //   }
  // }

  public gotoCatalogs() {
    this.router.navigate(['/registered/layout/catalogs', "catalogs"]);
  }

  // public cancelOrder() {
  //   const dialog = this.dialogService.open(CancelOrderDialogComponent);
  //   dialog.afterClosed.pipe(
  //     take(1),
  //     tap(o => {
  //       if (o) {
  //         this.cartService.emptyCart();
  //         this.router.navigate(['/registered/welcome']);
  //       }
  //     })
  //   ).subscribe();
  // }

  private someDeskHasSmsEnabled() {
    return this.order.some(i => {
      if (i['data']['catalog']['turnDesks']) {
        return i['data']['catalog']['turnDesks'][0]['turnDeskHasSmsNotify'];
      } else {
        return false;
      }
    });
  }

  private openLegalterms() {
    const dialog = this.dialogService.open(LegalTermsDialogComponent, { modal: false });
    dialog.afterClosed.pipe(
      take(1),
      tap(o => {
        if (o) {
          this.router.navigate(['/registered/layout/payment']);
        }
      })
    ).subscribe();
  }

  private getMCatalogService() {
    this.getMCatalogBannerSubscription = this.companyService.getMCatalogServices().pipe(
      take(1),
      tap(o => {
        this.services = o;
        switch (o.length) {
          // case 0:
          //   this.setType(null);
          //   break;
          // case 1:
          //   this.setType(o[0].Service);
          //   break;
        }
      })
    ).subscribe();
  }

  public mHelp() {
    this.mhelpService.openmHelp();
  }

}
