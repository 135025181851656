import {
  Component,
  OnInit,
  OnDestroy,
  OnChanges,
  SimpleChanges,
  ChangeDetectorRef,
} from "@angular/core";
import { CartService } from "src/app/services/cart-services/cart.service";
import { tap } from "rxjs/internal/operators/tap";
import { Subscription } from "rxjs";
import { Router, ActivatedRoute } from "@angular/router";
import { StorageService } from "src/app/services/storage-service/storage.service";
import { DialogService } from "../dialog/dialog.service";
import { CancelOrderDialogComponent } from "../cancel-order-dialog/cancel-order-dialog.component";
import { CatalogsService } from "src/app/services/catalogs-services/catalogs.service";
import { ProductDialogComponent } from "../product-dialog/product-dialog.component";
import { PaymentService } from "src/app/services/payments-services/payment.service";
import { TranslateService } from "@ngx-translate/core";
import { take } from "rxjs/internal/operators/take";
import { RfidService } from "src/app/services/rfid-services/rfid.service";
import { MhelpService } from "src/app/services/mhelp-services/mhelp.service";

@Component({
  selector: "app-products",
  templateUrl: "./products.component.html",
  styleUrls: ["./products.component.scss"],
})
export class ProductsComponent implements OnInit, OnChanges, OnDestroy {
  center;
  banner;
  catalogs;
  catalog;
  loadingCatalog;
  order;
  total;
  primaryColor;
  secondaryColor;
  assistanceActive;
  listenCatalogsRefreshSubscription: Subscription;
  listenCatalogRefreshSubscription: Subscription;
  listenChangesSubscription: Subscription;
  getCatalogSubscription: Subscription;
  loadingAnimationConfig;
  loading: boolean;
  seleccionado;

  isCatalogsVisible;
  categories;
  currentCategory;

  constructor(
    private cartService: CartService,
    private translateService: TranslateService,
    private storageService: StorageService,
    private catalogsService: CatalogsService,
    private dialogService: DialogService,
    private paymentService: PaymentService,
    private cd: ChangeDetectorRef,
    private mhelpService: MhelpService,
    private rfidService: RfidService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    this.isCatalogsVisible = true;
    this.listenCartChangesRefresh();
    this.listenCatalogsRefresh();
    this.listenCatalogRefresh(); //productos
    this.setLoadingAnimationConfig();
    this.loading = true;

    this.catalogsService
      .getCatalog(this.activatedRoute.snapshot.paramMap.get("catalog"))
      .pipe(
        take(1),
        tap((catalog) => (this.catalog = catalog)),
        tap((catalog) => (this.categories = this.getCategories())),
        tap(o => this.loading = false),

      )
      .subscribe();

    this.catalogsService
      .getCatalogs()
      .pipe(
        take(1),
        tap((catalogs) => (this.catalogs = catalogs)),
        tap((catalogs) => this.cd.detectChanges()),
        tap((catalogs) =>
          this.catalogsService.selectCatalog(
            this.activatedRoute.snapshot.paramMap.get("catalog")
          )
        )
      )
      .subscribe();
  
    this.listenCartChangesRefresh();
    this.order = this.cartService.getCart();
    console.log(this.order);
  
  
  }

  private getCatalogs() {
    //todos
    this.catalogsService
      .getCatalogs()
      .pipe(
        take(1),
        tap((o) => (this.catalogs = o)),


      )
      .subscribe();
  }

  private getCatalog(internalId) {
    //uno en concreto

    this.catalogsService
      .getCatalog(internalId)
      .pipe(
        take(1),
        tap((o) => (this.loadingCatalog = false)),
        tap((o) => (this.catalog = o)),
      )
      .subscribe();
  }

  public selectCatalog(internalId) {
    this.catalogs.forEach((c) =>
      c.internalId === internalId ? (c.selected = true) : (c.selected = false)
    );
    this.catalogsService.refreshCatalog(internalId);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.catalog &&
      changes.catalog.currentValue &&
      !changes.catalog.previousValue
    ) {
      this.categories = this.getCategories();
      this.currentCategory = null;
    } else if (
      changes.catalog &&
      changes.catalog.currentValue &&
      changes.catalog.previousValue
    ) {
      if (
        changes.catalog.currentValue.InernalId !==
        changes.catalog.previousValue.InternalId
      ) {
        this.categories = this.getCategories();
        this.currentCategory = null;
      }
    }
  }

  public verSelecionado(seleccionado) {
    this.selectCategory(seleccionado);
  }

  public selectCategory(category) {
    console.log(category)
    this.currentCategory === category
      ? (this.currentCategory = null)
      : (this.currentCategory = category);
  }

  private getCategories() {
    if (this.catalog) {
      const categories = [];
      this.catalog.products.forEach((p) => {
        //no existe
        if (!categories.includes(p.Category)) {
          categories.push(p.Category);
        }
      });
      return categories;
    }
  }

  public openProductModal(product) {
    const dialog = this.dialogService.open(ProductDialogComponent, {
      data: { product, catalog: this.catalog },
      modal: true,
    });
    dialog.afterClosed.pipe().subscribe();
  }

  private getPayments() {
    this.paymentService
      .getPayments()
      .pipe(
        take(1),
        tap((o) => this.storageService.setPayments(o))
      )
      .subscribe();
  }

  // public setLang(lang) {
  //   this.translateService.use(lang);
  //   this.storageService.setLang(lang);
  // }

  private listenCatalogsRefresh() {
    if (this.listenCatalogsRefreshSubscription) {
      this.listenCatalogsRefreshSubscription.unsubscribe();
    }
    this.listenCatalogsRefreshSubscription = this.catalogsService
      .listenRefreshCatalogs()
      .pipe(tap((o) => (this.catalogs = this.getCatalogs())),
      )
      .subscribe();
  }

  private listenCatalogRefresh() {
    //esta a la escucha de que se refreque algo de las categorias
    if (this.listenCatalogRefreshSubscription) {
      this.listenCatalogRefreshSubscription.unsubscribe();
    }
    this.listenCatalogRefreshSubscription = this.catalogsService
      .listenRefreshCatalog()
      .pipe(
        tap((o) => (this.loadingCatalog = true)),
        tap((o) => (this.catalog = this.getCatalog(o))),
        tap((o) => (this.selectCategory(null))), //Filtros reset,

        

      )
      .subscribe();
  }

  private listenCartChangesRefresh() {
    if (this.listenChangesSubscription) {
      this.listenChangesSubscription.unsubscribe();
    }
    this.listenChangesSubscription = this.cartService
      .listenChanges()
      .pipe(
        tap((o) => (this.order = o)),
        tap((o) => (this.total = this.cartService.getCartTotal()))
      )
      .subscribe();
  }

  public mHelp() {
    this.mhelpService.openmHelp();
  }

  ngOnDestroy() {
    this.rfidService.switchOffRfid();
    if (this.listenChangesSubscription) {
      this.listenChangesSubscription.unsubscribe();
    }
    if (this.listenCatalogsRefreshSubscription) {
      this.listenCatalogsRefreshSubscription.unsubscribe();
    }
    if (this.listenCatalogRefreshSubscription) {
      this.listenCatalogRefreshSubscription.unsubscribe();
    }

    if (this.getCatalogSubscription) {
      this.getCatalogSubscription.unsubscribe();
    }
  }

  
  private setLoadingAnimationConfig() {
    this.loadingAnimationConfig = {
      path: 'assets/animations/loading.animation.json',
      renderer: 'canvas',
      autoplay: true,
      loop: true
    };
  }
}

