import { ChangeDetectorRef, Component, ElementRef, OnInit } from '@angular/core';
import { Location } from '@angular/common'
import { Subscription } from 'rxjs';
import { PaymentService } from 'src/app/services/payments-services/payment.service';
import { Router } from '@angular/router';
import { DialogService } from '../dialog/dialog.service';
import { CancelOrderDialogComponent } from '../cancel-order-dialog/cancel-order-dialog.component';
import { tap } from 'rxjs/internal/operators/tap';
import { CartService } from 'src/app/services/cart-services/cart.service';
import { PricePipe } from 'src/app/pipes/price.pipe';
import { InactivityService } from 'src/app/services/inactivity-service/inactivity.service';
import { StorageService } from 'src/app/services/storage-service/storage.service';
import { flatMap, take } from 'rxjs/internal/operators';
import { OrderService } from 'src/app/services/order-services/order.service';
import { ConfirmProduct } from 'src/app/models/confirm-product.model';
import { Payment } from 'src/app/models/payment.model';
import { MhelpService } from 'src/app/services/mhelp-services/mhelp.service';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit {

  properties;
  banner;
  payment;
  payments;
  selectedPayment;
  primaryColor;
  assistanceActive;
  loadingAnimationConfig;
  loading: boolean;
  getPaymentsSubscription: Subscription;
  active;
  total;
  order;

  constructor(private router: Router,
              private eRef: ElementRef,
              private cd: ChangeDetectorRef,
              private cartService: CartService,
              private pricePipe: PricePipe,
              private inactivityService: InactivityService,
              private paymentService: PaymentService,
              private storageService: StorageService,
              private mhelpService: MhelpService,
              private orderService: OrderService,
              private dialogService: DialogService,
              private location: Location) { }

  ngOnInit() {
    this.total = this.cartService.getCartTotal();
    this.order = this.cartService.getCart();

    this.payments = this.paymentService.getSavedPayments();
    this.banner = this.storageService.getMCatalogBanner();
    this.primaryColor = this.storageService.getMCatalogPrimaryColor();
    this.assistanceActive = this.storageService.getMCatalogAssistanceActive();
    this.setLoadingAnimationConfig();

    console.log(this.storageService.getCustomerName())
    console.log(this.storageService.getCustomerEmail())
    console.log(this.storageService.getCustomerPhone())
    
  }

  public cancel(): void {
    this.location.back()  
  }

  public selectPayment(payment: Payment,i) {
    console.log(this.payment)
    console.log(payment)
    this.active = i;

    return this.payment = payment;
  }

  public gotPayment() {
    console.log(this.payment)
    this.loading = true;
    this.storageService.setPayment(this.payment.Type.Code);
    switch (this.payment.Type.Code) {
      case 'WEB':
        switch (this.payment.Gateway.Code)  {
          case 'BIZUM':
            this.orderService.preConfirmOrder(this.getProducts(), this.cartService.getCartTotal(), this.storageService.getSmsPhone()).pipe(
              tap(o => this.storageService.setTicketHTML(o['htmlTicketCustomer'])),
              flatMap(o => this.paymentService.getPaymentAuthorizationData(this.payment.InternalId)),
              tap(o => this.properties = o),
              tap(o => this.loading = false),
              tap(o => this.cd.detectChanges()),
              tap(o => this.eRef.nativeElement.children[2].submit())
            ).subscribe();
            break;
        }
        break;
      case 'CASH':
        this.orderService.preConfirmOrder(this.getProducts(), this.cartService.getCartTotal(), this.storageService.getSmsPhone()).pipe(
          tap(o => this.storageService.setTicketHTML(o['htmlTicketCustomer'])),
          flatMap(o => this.paymentService.getPaymentAuthorizationData(this.payment.InternalId)),
          flatMap(o => this.paymentService.confirmPayment(o['urlNotification'], '0', '')),
          tap(o => this.loading = false),
          tap(o => this.router.navigate(['/registered/printing']))
        ).subscribe();
        break;
      case 'DATAPHONE':
        this.orderService.preConfirmOrder(this.getProducts(), this.cartService.getCartTotal(), this.storageService.getSmsPhone()).pipe(
          tap(o => this.storageService.setTicketHTML(o['htmlTicketCustomer'])),
          tap(o => this.inactivityService.cancelInactivityTimer()),
          tap(o => this.paymentService.sendPayToDataphone(this.payment, this.pricePipe.transform(this.cartService.getCartTotal(), 'flat'))),
          tap(o => this.loading = false),
          tap(o => this.router.navigate(['/registered/waiting-dataphone', this.payment.InternalId]))
        ).subscribe();
        break;
      case 'MPAY':
        this.orderService.preConfirmOrder(this.getProducts(), this.cartService.getCartTotal(), this.storageService.getSmsPhone()).pipe(
          tap(o => this.storageService.setTicketHTML(o['htmlTicketCustomer'])),
          tap(o => this.inactivityService.cancelInactivityTimer()),
          flatMap(o => this.paymentService.getPaymentAuthorizationData(this.payment.InternalId)),
          tap(o => this.loading = false),
          tap(o => this.router.navigate(['/registered/waiting-pay', o.url])),
        ).subscribe();
        break;
    }
  }

  public gotoCatalogs() {
    this.router.navigate(['/registered/layout/catalogs', "catalogs"]);
  }

  public cancelOrder() {
    const dialog = this.dialogService.open(CancelOrderDialogComponent);
    dialog.afterClosed.pipe(
      take(1),
      tap(o => {
        if (o) {
          this.cartService.emptyCart();
          this.router.navigate(['/registered/welcome']);
        }
      })
    ).subscribe();
  }

  public mHelp() {
    this.mhelpService.openmHelp();
  }

  private getProducts() {
    const products = new Array<ConfirmProduct>();
    this.cartService.getCart().forEach(p => {
      const product = new ConfirmProduct(
        p['data']['qty'],
        p['data']['product']['gtin'],
        p['data']['product']['internalCode'],
        p['data']['product']['description'],
        p['data']['product']['tradename'],
        p['data']['product']['image'],
        p['data']['product']['pricePVP'],
        p['data']['product']['pricePVPOffer'],
        p['data']['product']['taxAmount'],
        p['data']['preparation'] ? p['data']['preparation']['description'] : '',
        p['data']['catalog']['turnDesks'] ? p['data']['catalog']['turnDesks'][0]['turnDeskInternalId'] : '',
        p['data']['product']['category'],
        p['data']['product']['categoryCode'],
        p['data']['product']['measure'],
        p['data']['product']['measureUnit']
      );
      products.push(product);
    });
    return products;
  }

  private setLoadingAnimationConfig() {
    this.loadingAnimationConfig = {
      path: 'assets/animations/loading.animation.json',
      renderer: 'canvas',
      autoplay: true,
      loop: true
    };
  }
}
