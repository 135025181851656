import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Console } from 'console';

@Injectable({
  providedIn: 'root'
})
export class MaintenanceDaoService {

  private BASE_URL = environment.apiURL;
  private OPTIONS = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json; charset=UTF-8'
    })
  };

  constructor(private http: HttpClient) { }
}
