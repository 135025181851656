import { Catalog } from './catalog.model';
import { Preparation } from './preparation.model';
import { Price } from './price.model';
import { Tax } from './tax.model';

export class Product {

    private additionalInfo: string;
    private catalog: Catalog;
    private category: string;
    private description: string;
    private gtin: string;
    private image: string;
    private internalCode: string;
    private internalId: string;
    private measure: number;
    private measureUnit: string;
    private preparations: Preparation[];
    private pricePVP: Price;
    private pricePVPOffer: Price;
    private taxAmount: Tax;
    private tradename: string;

    constructor(additionalInfo?: string, catalog?: Catalog, category?: string, description?: string, gtin?: string, image?: string, internalCode?: string, internalId?: string, measure?: number, measureUnit?: string, preparations?: Preparation[], pricePVP?: Price, pricePVPOffer?: Price, taxAmount?: Tax, tradename?: string) {
        this.additionalInfo = additionalInfo;
        this.catalog = catalog;
        this.category = category;
        this.description = description;
        this.gtin = gtin;
        this.image = image;
        this.internalCode = internalCode;
        this.internalId = internalId;
        this.measure = measure;
        this.measureUnit = measureUnit;
        this.preparations = preparations;
        this.pricePVP = pricePVP;
        this.pricePVPOffer = pricePVPOffer;
        this.taxAmount = taxAmount;
        this.tradename = tradename;
    }

    public get AdittionalInfo() {
        return this.additionalInfo;
    }

    public set AdittionalInfo(additionalInfo) {
        this.additionalInfo = additionalInfo;
    }

    public get Catalog() {
        return this.catalog;
    }

    public set Catalog(catalog) {
        this.catalog = catalog;
    }

    public get Category() {
        return this.category;
    }

    public set Category(category) {
        this.category = category;
    }

    public get Description() {
        return this.description;
    }

    public set Description(description) {
        this.description = description;
    }

    public get GTIN() {
        return this.gtin;
    }

    public set GTIN(gtin) {
        this.gtin = gtin;
    }

    public get Image() {
        return this.image;
    }

    public set Image(image) {
        this.image = image;
    }

    public get InternalCode() {
        return this.internalCode;
    }

    public set InternalCode(internalCode) {
        this.internalCode = internalCode;
    }

    public get InternalId() {
        return this.internalId;
    }

    public set InternalId(internalId) {
        this.internalId = internalId;
    }

    public get Measure() {
        return this.measure;
    }

    public set Measure(measure) {
        this.measure = measure;
    }

    public get MeasureUnit() {
        return this.measureUnit;
    }

    public set MeasureUnit(measureUnit) {
        this.measureUnit = measureUnit;
    }

    public get Preparations() {
        return this.preparations;
    }

    public set Preparations(preparations) {
        this.preparations = preparations;
    }

    public get PricePVP() {
        return this.pricePVP;
    }

    public set PricePVP(pricePVP) {
        this.pricePVP = pricePVP;
    }

    public get PricePVPOffer() {
        return this.pricePVPOffer;
    }

    public set PricePVPOffer(pricePVPOffer) {
        this.pricePVPOffer = pricePVPOffer;
    }

    public get TaxAmount() {
        return this.taxAmount;
    }

    public set TaxAmount(taxAmount) {
        this.taxAmount = taxAmount;
    }

    public get Tradename() {
        return this.tradename;
    }

    public set Tradename(tradename) {
        this.tradename = tradename;
    }

}
