import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MhelpService } from 'src/app/services/mhelp-services/mhelp.service';
import { StorageService } from 'src/app/services/storage-service/storage.service';

@Component({
  selector: 'app-payment-error',
  templateUrl: './payment-error.component.html',
  styleUrls: ['./payment-error.component.scss']
})
export class PaymentErrorComponent implements OnInit {

  banner;
  primaryColor;
  errorConfig;

  constructor(private storageService: StorageService,
              private mhelpService: MhelpService,
              private router: Router) { }

  ngOnInit() {
    this.setErrorAnimation();
    this.primaryColor = this.storageService.getMCatalogPrimaryColor();
    this.banner = this.storageService.getMCatalogBanner();
  }

  public retryPayment() {
    this.router.navigate(['/registered/layout/payment']);
  }

  public mHelp() {
    this.mhelpService.openmHelp();
  }

  private setErrorAnimation() {
    this.errorConfig = {
      path: 'assets/animations/error.animation.json',
      renderer: 'canvas',
      autoplay: true,
      loop: true
    };
  }

}
