import { Injectable, isDevMode } from '@angular/core';
import { StorageService } from '../storage-service/storage.service';
import { Observable } from 'rxjs/internal/Observable';
import { MaintenanceDaoService } from 'src/app/dao/maintenance-dao-services/maintenance-dao.service';
import { Device } from 'src/app/models/device.model';
import { APP_CONFIG } from 'src/config';
import { sha256 } from 'js-sha256';
import { Subject } from 'rxjs/internal/Subject';
import { FingerprintService } from '../fingerprint-services/fingerprint.service';
import { take } from 'rxjs/internal/operators';
declare const Android;

@Injectable({
  providedIn: 'root'
})
export class MaintenanceService {

  appVersion: string;

  constructor(private storageService: StorageService,
              private fingerprintService: FingerprintService,
              private maintenanceDaoService: MaintenanceDaoService) {
                this.appVersion = APP_CONFIG.appVersion;
              }

  public isRegistered() {
    return Observable.create( 
    (o) => {

      if (this.storageService.getContext()) {
                  o.next({isRegistered: true, context: this.storageService.getContext()});
                } else if (this.storageService.getOAuthToken) {
                  o.next({isRegistered: false, token: this.storageService.getOAuthToken()});
                }
    }
    );
  }

  public fingerPrintDevice(fingerprint, isRealDeviceFingerprint) {
    const device = new Device();
    device.Application = isRealDeviceFingerprint ? fingerprint['application'] : 'M_CATALOG';
    device.DeviceType = isRealDeviceFingerprint ? fingerprint['deviceType'] : 'WEB';
    device.Mac = isRealDeviceFingerprint ? fingerprint['mac'] : 'FF:FF:FF:FF';
    device.DeviceName = '';
    device.Description = isRealDeviceFingerprint ? fingerprint['description'] : '';
    device.Note = '';
    device.Battery = isRealDeviceFingerprint ? fingerprint['battery'] : '100';
    device.Brand = isRealDeviceFingerprint ? fingerprint['brand'] : '';
    device.Model = isRealDeviceFingerprint ? fingerprint['model'] : '';
    device.SerialNumber = isRealDeviceFingerprint ? fingerprint['serialNumber'] : sha256(fingerprint['components'][19].value + ' / ' + fingerprint['components'][0].value).replace(/\D/g, '').slice(0, 15);
    device.Platform = isRealDeviceFingerprint ? fingerprint['platform'] : 'WEB';
    device.OsVersion = isRealDeviceFingerprint ? fingerprint['osVersion'] : fingerprint['components'][15].value;
    device.AppVersion = isRealDeviceFingerprint ? fingerprint['appVersion'] : this.appVersion;
    device.ConnectionDevice = isRealDeviceFingerprint ? fingerprint['connectionDevice'] : '-';
    device.WifiSsid = isRealDeviceFingerprint ? fingerprint['wifiSsid'] : '-';
    device.IpAddress = isRealDeviceFingerprint ? fingerprint['ipAddress'] : 'unavailable';
    device.Signal = 0;
    device.Major = 0;
    device.Minor = 0;
    device.PaperOut = false;
    device.Brightness = 0;
    device.Volume = 0;
    device.NotifyBackOffice = true;
    device.Locked = false;
    return device;
  }

  public refreshDeviceInfo() {
    window.location.reload();
  }

  public sendContext(context) {
    try {
      // tslint:disable-next-line:only-arrow-functions
      (function() { Android.context(context); })();
    } catch (e) {
      console.dir(e);
    }
  }
}
