import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { StorageService } from 'src/app/services/storage-service/storage.service';
import { COUNTRY_PREFIXES } from 'src/assets/data/country-prefixes';

@Component({
  selector: 'app-numeric-pad',
  templateUrl: './numeric-pad.component.html',
  styleUrls: ['./numeric-pad.component.scss']
})
export class NumericPadComponent implements OnInit {

  @Output() confirm = new EventEmitter<string>();
  currentPrefix;
  flagIcon;
  primaryColor;
  smsForm = new FormGroup({
    phoneNumber: new FormControl(null, Validators.required)
  });

  constructor(private storageService: StorageService) {
  }

  ngOnInit() {
    this.flagIcon =  'assets/icons/flags/unknown.png';
    this.primaryColor = this.storageService.getMCatalogPrimaryColor();
    this.getInitialPrefix();
    this.getFlagIcon();
  }

  public pad(e) {
    this.smsForm.markAsUntouched();
    const fc = this.smsForm.get('phoneNumber');
    switch (e) {
      case '+':
        if (fc.value.length === 0) { fc.patchValue(fc.value + e); }
        break;
      default:
        fc.patchValue(fc.value + e);
    }
    this.phoneNumberOnChange();
  }

  public delete() {
    if (this.smsForm.get('phoneNumber').value && this.smsForm.get('phoneNumber').value.length > 0) {
      this.smsForm.get('phoneNumber').patchValue(this.smsForm.get('phoneNumber').value.slice(0, -1));
      this.phoneNumberOnChange();
    }
  }

  public accept() {
    if (this.isValid()) {
      this.confirm.emit(this.smsForm.get('phoneNumber').value);
    } else {
      this.smsForm.markAsTouched();
    }
  }

  private phoneNumberOnChange(e?) {
    if (this.smsForm.get('phoneNumber').value) {
      if ((new RegExp('^[+]')).test(this.smsForm.get('phoneNumber').value)) {
        if (COUNTRY_PREFIXES[this.smsForm.get('phoneNumber').value]) {
          this.currentPrefix = COUNTRY_PREFIXES[this.smsForm.get('phoneNumber').value];
        }
      } else {
        this.currentPrefix = null;
      }
    } else {
      this.currentPrefix = null;
    }
    this.getFlagIcon();
  }

  private getFlagIcon() {
    if (this.currentPrefix) {
      this.flagIcon = 'assets/icons/flags/' + this.currentPrefix.toLowerCase() + '.png';
    } else {
      this.flagIcon = 'assets/icons/flags/unknown.png';
    }
  }

  private getInitialPrefix() {
    switch (this.storageService.getLang()) {
      case 'en_GB':
        this.smsForm.patchValue({
          phoneNumber: '+44'
        });
        this.phoneNumberOnChange();
        break;
      case 'es_ES':
        this.smsForm.patchValue({
          phoneNumber: '+34'
        });
        this.phoneNumberOnChange();
        break;
      case 'fr_FR':
        this.smsForm.patchValue({
          phoneNumber: '+33'
        });
        this.phoneNumberOnChange();
        break;
      case 'it_IT':
        this.smsForm.patchValue({
          phoneNumber: '+39'
        });
        this.phoneNumberOnChange();
        break;
      case 'pt_PT':
        this.smsForm.patchValue({
          phoneNumber: '+351'
        });
        this.phoneNumberOnChange();
        break;
      case 'nl_NL':
        this.smsForm.patchValue({
          phoneNumber: '+32'
        });
        this.phoneNumberOnChange();
        break;
      default:
        return null;
    }
  }

  public isValid() {
    if (this.smsForm.valid && this.currentPrefix) {
      const phoneNumberLength = ((this.smsForm.get('phoneNumber').value).replace(/\s/g, '')).length;
      if (phoneNumberLength > 6) {
        return true;
      }
    }
  }

}
