import { Component, OnInit } from "@angular/core";
import { MaintenanceService } from "src/app/services/maintenance-service/maintenance.service";
import { CommunicationService } from "src/app/services/communication-services/communication.service";
import { CompanyService } from "src/app/services/company-services/company.service";
import { StorageService } from "src/app/services/storage-service/storage.service";
import { Router, ActivatedRoute } from "@angular/router";
import { map } from "rxjs/internal/operators/map";
import { tap } from "rxjs/internal/operators/tap";
import { take } from "rxjs/internal/operators";

@Component({
  selector: "app-registered",
  templateUrl: "./registered.component.html",
  styleUrls: ["./registered.component.scss"],
})
export class RegisteredComponent implements OnInit {
  brightness: number;
  loading;

  constructor(
    private maintenanceService: MaintenanceService,
    private communicationService: CommunicationService,
    private companyService: CompanyService,
    private storageService: StorageService,
    private router: Router,
    private activatedRoute : ActivatedRoute
  ) {}

  ngOnInit() {
    this.isRegistered();
  }

  private isRegistered() {
    this.loading = true;
    this.maintenanceService
      .isRegistered()
      .pipe(
        take(1),
        map((registered) => { // map genera obserbable
          if (!registered["isRegistered"]) {

          } 
            this.storageService.setContext(this.storageService.getUrlContext);
            this.maintenanceService.sendContext(this.storageService.getUrlContext);
            this.companyService
              .getCenter()
              .pipe(
                take(1),
                tap((o) => this.storageService.setCenter(o)),
                tap((o) => (this.loading = false))
              )
              .subscribe();
            this.companyService
              .getMCatalogConfig()
              .pipe(
                take(1),
                tap((o) => this.storageService.setMCatalogBanner(o["banner"])),
                tap((o) =>
                  this.storageService.setMCatalogPrimaryColor(o["primaryColor"])
                ),
                tap((o) =>
                  this.storageService.setMCatalogSecondaryColor(
                    o["secondaryColor"]
                  )
                ),
                tap((o) =>
                  this.storageService.setMCatalogInactivityTime(
                    o["inactivityTime"]
                  )
                ),
                tap((o) =>
                  this.storageService.setMCatalogAssistanceActive(
                    o["assistanceActive"].toString()
                  )
                )
              )
              .subscribe();          
        })
      )
      .subscribe();
  }

}
