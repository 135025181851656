import { Injectable, ComponentRef, ComponentFactoryResolver, ApplicationRef, Injector, EmbeddedViewRef, Type } from '@angular/core';
import { Subject } from 'rxjs';
import { DialogOptions } from './dialog.model';
import { DialogComponent } from './dialog.component';
import { DialogInjector } from './dialog.injector';
import { DialogRef } from './dialog-ref';

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  private dialogs = new Array<DialogRef>();
  private dialogComponentRef: ComponentRef<DialogComponent>;
  private listener = new Subject<any>();

  constructor(private componentFactoryResolver: ComponentFactoryResolver,
              private appRef: ApplicationRef,
              private injector: Injector) { }

  public open(componentType: Type<any>, options?: DialogOptions) {
    const dialogRef = this._insertDialog(options);
    this.dialogComponentRef.instance.childComponentType = componentType;
    this.dialogs.push(dialogRef);
    return dialogRef;
  }

  public closeAll() {
    this.dialogs.forEach(d => d.close());
    this.dialogs = new Array<DialogRef>();
  }

  public listen() {
    return this.listener.asObservable();
  }

  public _insertDialog(options?: DialogOptions): DialogRef {
    const map = new WeakMap();
    map.set(DialogOptions, options);
    const dialogRef = new DialogRef();
    map.set(DialogRef, dialogRef);
    const sub = dialogRef.afterClosed.subscribe(() => {
      this._removeDialog();
      sub.unsubscribe();
    });

    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(DialogComponent);
    const componentRef = componentFactory.create(new DialogInjector(this.injector, map));
    this.appRef.attachView(componentRef.hostView);

    const domElem = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
    document.body.appendChild(domElem);
    this.dialogComponentRef = componentRef;

    this.dialogComponentRef.instance.onClose.subscribe(() => {
      if (!options || !options.modal === false ) {
        this._removeDialog();
      }
    });

    return dialogRef;
  }

  public _removeDialog() {
    this.appRef.detachView(this.dialogComponentRef.hostView);
    this.dialogComponentRef.destroy();
  }
}
