import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { CartItem, CartItemData, CartItemOrigin } from 'src/app/models/cart-item.model';
import { Catalog } from 'src/app/models/catalog.model';
import { Desk } from 'src/app/models/desk.model';
import { Preparation } from 'src/app/models/preparation.model';
import { Price } from 'src/app/models/price.model';
import { Product } from 'src/app/models/product.model';
import { Tax } from 'src/app/models/tax.model';
import { StorageService } from '../storage-service/storage.service';

@Injectable({
  providedIn: 'root'
})
export class CartService {

  private changesListener = new Subject<Array<CartItem>>();
  private cart: Array<CartItem> = [];

  constructor(private storageService: StorageService) { }

  public getCart(): Array<CartItem> {
    return this.cart;
  }

  public getSavedCart(): Array<CartItem> {
    return this.parseCart(this.storageService.getCart());
  }

  public emptyCart() {
    this.cart = [];
    this.cartChanges();
  }

  public addItem(id: number, data: CartItemData, origin: CartItemOrigin) {
    const cartItem = new CartItem(id, data, origin);
    this.cart.push(cartItem);
    this.cartChanges();
  }

  public getProductQuantity(product){
    let cont = 0;
    for (let i = 0; i < this.cart.length; i++) {
      if(product.Description == this.cart[i].Data.Product.Description){
        cont = cont + this.cart[i].Data.Qty;
      } 
    }
    return cont;
  }


  public qtyChange(index,qty){
    this.cart[index].Data.Qty = qty;
    this.cartChanges();
  }

  public deleteItem(id) {
    // let index = this.cart.findIndex(i => i.Id === id);
    this.cart.splice(id, 1);
    this.cartChanges();
  }

  public deleteItemsByOrigin(origin: CartItemOrigin) {
    this.cart = this.cart.filter(c => c.Origin !== origin);
    this.cartChanges();
  }

  public listenChanges() {
    return this.changesListener.asObservable();
  }

  public cartChanges() {
    this.storageService.setCart(this.cart); // pasa como argumento la variable this.cart
    this.changesListener.next(this.cart); // changesListener se utiliza cuando se producen cambios en el carrito.
  }

  public getCartTotal(): Price {
    let flatTotal = '0';
    flatTotal = this.cart.reduce((previous, current) => {
      if (current.Data.Product.PricePVPOffer.Significand !== 0) {
        return previous + (current.Data.Qty / current.Data.Product.Measure * parseFloat((current.Data.Product.PricePVPOffer.Significand * Math.pow(10, current.Data.Product.PricePVPOffer.Exponent)).toFixed(2)));
      }
      return previous + (current.Data.Qty / current.Data.Product.Measure * parseFloat((current.Data.Product.PricePVP.Significand * Math.pow(10, current.Data.Product.PricePVP.Exponent)).toFixed(2)));
    }, 0).toFixed(2);
    const total = {
      significand: parseInt(flatTotal.replace(/[\.\,]/g, ''), 10),
      exponent: flatTotal.split(/[\.\,]/g).length > 1 ? flatTotal.split(/[\.\,]/g)[1].length * -1 : 0,
      currency: '€'
    };
    return Object.assign(new Price(), total);
  }

  public parseCart(unparsedCart) {
    const parsedCart = new Array<CartItem>();

    unparsedCart.forEach(i => {
      const cartItem = new CartItem();
      Object.assign(cartItem, i);

      const cartItemData = new CartItemData();
      Object.assign(cartItemData, i['data']);

      const catalog = new Catalog();
      Object.assign(catalog, i['data']['catalog']);

      const products = new Array<Product>();
      if (i['data']['catalog']['products']) {
        i['data']['catalog']['products'].forEach(p => {
          const prod = new Product();
          Object.assign(prod, p);

          const pricePVP = new Price();
          Object.assign(pricePVP, p['pricePVP']);
          prod.PricePVP = pricePVP;

          const pricePVPOffer = new Price();
          Object.assign(pricePVPOffer, p['pricePVPOffer']);
          prod.PricePVPOffer = pricePVPOffer;

          const taxAmount = new Tax();
          Object.assign(taxAmount, p['taxAmount']);
          prod.TaxAmount = taxAmount;

          const preparations = new Array<Preparation>();
          p['preparations'].forEach(pre => {
            const prep = new Preparation();
            Object.assign(prep, pre);
            preparations.push(prep);
          });
          prod.Preparations = preparations;

          products.push(prod);
        });
      }

      catalog.Products = products;

      const desks = new Array<Desk>();
      if (i['data']['catalog']['turnDesks']) {
        i['data']['catalog']['turnDesks'].forEach(d => {
          const desk = new Desk();
          Object.assign(desk, d);
          desks.push(desk);
        });
      }
      catalog.TurnDesks = desks;

      cartItemData.Catalog = catalog;

      const preparation = new Preparation();
      Object.assign(preparation, i['data']['preparation']);
      cartItemData.Preparation = preparation;

      const product = new Product();
      Object.assign(product, i['data']['product']);
      cartItemData.Product = product;

      cartItem.Data = cartItemData;

      parsedCart.push(cartItem);
    });

    return parsedCart;
  }
}
