import { Injectable, isDevMode } from '@angular/core';
import { CatalogsDaoService } from 'src/app/dao/catalogs-dao-services/catalogs-dao.service';
import { StorageService } from '../storage-service/storage.service';
import { tap, map } from 'rxjs/internal/operators';
import { Subject } from 'rxjs';
import { Catalog } from 'src/app/models/catalog.model';
import { Product } from 'src/app/models/product.model';
import { Price } from 'src/app/models/price.model';
import { Tax } from 'src/app/models/tax.model';
import { Preparation } from 'src/app/models/preparation.model';
import { Desk } from 'src/app/models/desk.model';

@Injectable({
  providedIn: 'root'
})
export class CatalogsService {

  private refreshCatalogsListener = new Subject<any>();
  private refreshCatalogListener = new Subject<any>();
  private selectCatalogListener = new Subject<any>();

  constructor(private storageService: StorageService,
              private catalogsDaoService: CatalogsDaoService) { }

  public getCatalogs() {
    return this.catalogsDaoService.getCatalogs(this.storageService.getContext(), this.storageService.getLang()).pipe(
      tap(o => !isDevMode() || console.dir(o)),
      map(o => {
        const catalogs = new Array<Catalog>();
        o['data']['catalogs'].forEach(c => {
          const catalog = new Catalog();
          Object.assign(catalog, c);
          catalogs.push(catalog);
        });
        return catalogs;
      })
    );
  }

  public getCatalog(internalId) {
    return this.catalogsDaoService.getCatalog(this.storageService.getContext(), this.storageService.getLang(), internalId).pipe(
      tap(o => !isDevMode() || console.dir(o)),
      map(o => {
        const products = new Array<Product>();
        o['data']['catalog']['products'].forEach(p => {
          const product = new Product();
          Object.assign(product, p);
          products.push(product);

          const pricePVP = new Price();
          Object.assign(pricePVP, p['pricePVP']);
          product.PricePVP = pricePVP;

          const pricePVPOffer = new Price();
          Object.assign(pricePVPOffer, p['pricePVPOffer']);
          product.PricePVPOffer = pricePVPOffer;

          const taxAmount = new Tax();
          Object.assign(taxAmount, p['taxAmount']);
          product.TaxAmount = taxAmount;

          const preparations = new Array<Preparation>();
          p['preparations'].forEach(pre => {
            const preparation = new Preparation();
            Object.assign(preparation, pre);
            preparations.push(preparation);
          });
          product.Preparations = preparations;
        });
        o['data']['catalog']['products'] = products;

        const desks = new Array<Desk>();
        o['data']['catalog']['turnDesks'].forEach(d => {
          const desk = new Desk();
          Object.assign(desk, d);
          desks.push(desk);
        });
        o['data']['catalog']['turnDesks'] = desks;

        const catalog = new Catalog();
        Object.assign(catalog, o['data']['catalog']);
        return catalog;
      })
    );
  }

  public getTurn(catalogInternalId, turnDeskInternalId, orderId, smsPhoneNumber?) {
    return this.catalogsDaoService.getTurn(this.storageService.getContext(), this.storageService.getLang(), catalogInternalId, turnDeskInternalId, orderId, smsPhoneNumber).pipe(
      tap(o => !isDevMode() || console.dir(o)),
      map(o => o['data'])
    );
  }

  public getProductByGTIN(gtin) {
    return this.catalogsDaoService.getProductsByGTIN(this.storageService.getContext(), this.storageService.getLang(), gtin).pipe(
      tap(o => !isDevMode() || console.dir(o)),
      map(o => {
        const products = new Array<Product>();
        o['data']['products'].forEach(p => {
          const product = new Product();
          Object.assign(product, p);
          products.push(product);

          const pricePVP = new Price();
          Object.assign(pricePVP, p['pricePVP']);
          product.PricePVP = pricePVP;

          const pricePVPOffer = new Price();
          Object.assign(pricePVPOffer, p['pricePVPOffer']);
          product.PricePVPOffer = pricePVPOffer;

          const taxAmount = new Tax();
          Object.assign(taxAmount, p['taxAmount']);
          product.TaxAmount = taxAmount;

          const catalog = new Catalog();
          Object.assign(catalog, p['catalog']);
          product.Catalog = catalog;

          const preparations = new Array<Preparation>();
          p['preparations'].forEach(pre => {
            const preparation = new Preparation();
            Object.assign(preparation, pre);
            preparations.push(preparation);
          });
          product.Preparations = preparations;

          Object.assign(pricePVP, p['pricePVP']);
          product.PricePVP = pricePVP;
        });
        return products && products.length > 0 ? products[0] : null;
      })
    );
  }

  public getProductsByGTIN(gtin) {
    return this.catalogsDaoService.getProductsByGTIN(this.storageService.getContext(), this.storageService.getLang(), gtin).pipe(
      tap(o => !isDevMode() || console.dir(o)),
      map(o => {
        const products = new Array<Product>();
        o['data']['products'].forEach(p => {
          const product = new Product();
          Object.assign(product, p);
          products.push(product);

          const pricePVP = new Price();
          Object.assign(pricePVP, p['pricePVP']);
          product.PricePVP = pricePVP;

          const pricePVPOffer = new Price();
          Object.assign(pricePVPOffer, p['pricePVPOffer']);
          product.PricePVPOffer = pricePVPOffer;

          const taxAmount = new Tax();
          Object.assign(taxAmount, p['taxAmount']);
          product.TaxAmount = taxAmount;

          const catalog = new Catalog();
          Object.assign(catalog, p['catalog']);
          product.Catalog = catalog;

          const preparations = new Array<Preparation>();
          p['preparations'].forEach(pre => {
            const preparation = new Preparation();
            Object.assign(preparation, pre);
            preparations.push(preparation);
          });
          product.Preparations = preparations;

          Object.assign(pricePVP, p['pricePVP']);
          product.PricePVP = pricePVP;
        });
        return products;
      })
    );
  }

  public listenRefreshCatalogs() {
    return this.refreshCatalogsListener.asObservable();
  }

  public refreshCatalogs() {
    this.refreshCatalogsListener.next();
  }

  public listenRefreshCatalog() {
    return this.refreshCatalogListener.asObservable();
  }

  public refreshCatalog(catalog) {
    this.refreshCatalogListener.next(catalog);
  }

  public listenSelectCatalog() {
    return this.selectCatalogListener.asObservable();
  }

  public selectCatalog(catalog) {
    this.selectCatalogListener.next(catalog);
  }

}
