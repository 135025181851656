import { Component, OnInit, Input } from "@angular/core";
import { StorageService } from "src/app/services/storage-service/storage.service";
import { CartService } from "src/app/services/cart-services/cart.service";
import { Subscription } from "rxjs";
import { tap } from "rxjs/internal/operators/tap";

@Component({
  selector: "app-product-card",
  templateUrl: "./product-card.component.html",
  styleUrls: ["./product-card.component.scss"],
})
export class ProductCardComponent implements OnInit {
  @Input() description;
  @Input() tradename;
  @Input() image;
  @Input() price;
  @Input() priceOffer;
  @Input() measure;
  @Input() measureUnit;
  @Input() product;

  primaryColor;
  order;
  total;
  listenCatalogsRefreshSubscription: Subscription;
  listenCatalogRefreshSubscription: Subscription;
  listenChangesSubscription: Subscription;
  listenCartChangesSubscription: Subscription;

  cart;
  cont;

  constructor(
    private storageService: StorageService,
    private cartService: CartService
  ) {}

  ngOnInit() {
    this.cont = 0;
    this.listenCartChangesRefresh();
    this.primaryColor = this.storageService.getMCatalogPrimaryColor();
    this.order = this.cartService.getCart();
    this.cart = this.cartService.getCart();
    this.cont = this.cartService.getProductQuantity(this.product);
  }

  private listenCartChangesRefresh() {
    if (this.listenChangesSubscription) {
      this.listenChangesSubscription.unsubscribe();
    }
    this.listenChangesSubscription = this.cartService
      .listenChanges()
      .pipe(
        tap((o) => (this.order = o)),
        tap((o) => (this.total = this.cartService.getCartTotal())),
        tap((o) => (this.cont = this.cartService.getProductQuantity(this.product))),
      )
      .subscribe();
  }
}
