import { Component, OnInit, OnDestroy, ChangeDetectorRef } from "@angular/core";
import { CartService } from "src/app/services/cart-services/cart.service";
import { tap } from "rxjs/internal/operators/tap";
import { Subscription } from "rxjs";
import { Router, ActivatedRoute } from "@angular/router";
import { StorageService } from "src/app/services/storage-service/storage.service";
import { DialogService } from "../dialog/dialog.service";
import { CancelOrderDialogComponent } from "../cancel-order-dialog/cancel-order-dialog.component";
import { CatalogsService } from "src/app/services/catalogs-services/catalogs.service";
import { PaymentService } from "src/app/services/payments-services/payment.service";
import { TranslateService } from "@ngx-translate/core";
import { take } from "rxjs/internal/operators/take";
import { RfidService } from "src/app/services/rfid-services/rfid.service";
import { MhelpService } from "src/app/services/mhelp-services/mhelp.service";

@Component({
  selector: "app-catalogs",
  templateUrl: "./catalogs.component.html",
  styleUrls: ["./catalogs.component.scss"],
})
export class CatalogsComponent implements OnInit, OnDestroy {
  center;
  banner;
  catalogs;
  catalog;
  loadingCatalog;
  order;
  total;
  primaryColor;
  secondaryColor;
  assistanceActive;
  listenCatalogsRefreshSubscription: Subscription;
  listenCatalogRefreshSubscription: Subscription;
  listenChangesSubscription: Subscription;
  isCatalogsVisible;
  loadingAnimationConfig;
  loading: boolean;

  constructor(
    private cartService: CartService,
    private translateService: TranslateService,
    private storageService: StorageService,
    private catalogsService: CatalogsService,
    private dialogService: DialogService,
    private paymentService: PaymentService,
    private cd: ChangeDetectorRef,
    private mhelpService: MhelpService,
    private rfidService: RfidService,
    private router: Router
  ) {}

  ngOnInit() {


    this.isCatalogsVisible = true; //nuevo
    this.listenCartChangesRefresh();
    this.listenCatalogsRefresh();
    this.listenCatalogRefresh(); //productos
    this.setLoadingAnimationConfig();// animacion
    this.loading = true;

    //CATEGORIAS
    this.catalogsService.getCatalogs().pipe(
        take(1),
        tap((catalogs) => (this.catalogs = catalogs)),
        tap((catalogs) => this.cd.detectChanges()),
        tap((catalogs) => this.catalogsService.selectCatalog(this.catalogs[0].internalId)),
        // tap((catalogs) => console.log("CATALAGOS ->")),
        // tap((catalogs) => console.log(this.catalogsService.getCatalogs())),
        tap(o => this.loading = false),
      )
      .subscribe();

      //RECOGER INFO 
      this.center = this.storageService.getCenter();
      this.banner = this.storageService.getMCatalogBanner();
      this.primaryColor = this.storageService.getMCatalogPrimaryColor();
      this.secondaryColor = this.storageService.getMCatalogSecondaryColor();
      this.assistanceActive = this.storageService.getMCatalogAssistanceActive();
      this.order = this.cartService.getCart();
      this.total = this.cartService.getCartTotal();
      this.rfidService.switchOnRfid();
      // this.setLang(this.center.defaultLanguage.locale);
      this.getPayments();

  }

  public cancelOrder() {
    const dialog = this.dialogService.open(CancelOrderDialogComponent);
    dialog.afterClosed.pipe(
      take(1),
      tap(o => {
        if (o) {
          this.cartService.emptyCart();
          this.gotoWelcome();
        }
      })
    ).subscribe();
  }

  public gotoOrder() {
    this.router.navigate(['/registered/layout/order']);
  }

  // public setLang(lang) {
  //   this.translateService.use(lang);
  //   this.storageService.setLang(lang);
  // }

  private gotoWelcome() {
    this.router.navigate(['/registered/welcome']);
  }

  private getCatalogs() {
    this.catalogsService
      .getCatalogs()
      .pipe(
        take(1),
        tap((o) => (this.catalogs = o))
      )
      .subscribe();
  }

  private getCatalog(internalId) {
    this.catalogsService
      .getCatalog(internalId)
      .pipe(
        take(1),
        tap((o) => (this.loadingCatalog = false)),
        tap((o) => (this.catalog = o))
      )
      .subscribe();
  }

  private getPayments() {
    this.paymentService
      .getPayments()
      .pipe(
        take(1),
        tap((o) => this.storageService.setPayments(o))
      )
      .subscribe();
  }

  private listenCatalogsRefresh() {
    if (this.listenCatalogsRefreshSubscription) {
      this.listenCatalogsRefreshSubscription.unsubscribe();
    }
    this.listenCatalogsRefreshSubscription = this.catalogsService
      .listenRefreshCatalogs()
      .pipe(tap((o) => (this.catalogs = this.getCatalogs())))
      .subscribe();
  }

  private listenCatalogRefresh() { //esta a la escucha de que se refreque algo de las categorias
    if (this.listenCatalogRefreshSubscription) {
      this.listenCatalogRefreshSubscription.unsubscribe();
    }
    this.listenCatalogRefreshSubscription = this.catalogsService
      .listenRefreshCatalog()
      .pipe(
        tap((o) => (this.loadingCatalog = true)),
        tap((o) => (this.catalog = this.getCatalog(o))),
        tap((o) => (console.log("CATALOGS"))),
        tap((o) => (console.log(this.catalogs))),
        tap((o) => (console.log("CATALOG"))),
        tap((o) => (console.log( this.getCatalog(o)))),

      )
      .subscribe();
  }

  private listenCartChangesRefresh() {
    if (this.listenChangesSubscription) {
      this.listenChangesSubscription.unsubscribe();
    }
    this.listenChangesSubscription = this.cartService
      .listenChanges()
      .pipe(
        tap((o) => (this.order = o)),
        tap((o) => (this.total = this.cartService.getCartTotal()))
      )
      .subscribe();
  }

  public mHelp() {
    this.mhelpService.openmHelp();
  }

  ngOnDestroy() {
    this.rfidService.switchOffRfid();
    if (this.listenChangesSubscription) {
      this.listenChangesSubscription.unsubscribe();
    }
    if (this.listenCatalogsRefreshSubscription) {
      this.listenCatalogsRefreshSubscription.unsubscribe();
    }
    if (this.listenCatalogRefreshSubscription) {
      this.listenCatalogRefreshSubscription.unsubscribe();
    }
  }

  private setLoadingAnimationConfig() {
    this.loadingAnimationConfig = {
      path: 'assets/animations/loading.animation.json',
      renderer: 'canvas',
      autoplay: true,
      loop: true
    };
  }

}
