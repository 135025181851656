import { Url } from 'url';
import { PaymentType } from './payment-type.model';
import { PaymentGateway } from './payment-gateway.model';
import { PaymentPeripheral } from './payment-peripheral.model';

export class Payment {

    private active: boolean;
    private dataphonePeripherals: Array<PaymentPeripheral>;
    private gateway: PaymentGateway;
    private image: string;
    private internalId: string;
    private invoiceprefix: string;
    private name: string;
    private properties: string;
    private type: PaymentType;

    constructor(active?: boolean, dataphonePeripherals?: Array<PaymentPeripheral>, gateway?: PaymentGateway, image?: string, internalId?: string, invoiceprefix?: string, name?: string, properties?: string, type?: PaymentType) {
        this.active = active;
        this.dataphonePeripherals = dataphonePeripherals;
        this.gateway = gateway;
        this.image = image;
        this.internalId = internalId;
        this.invoiceprefix = invoiceprefix;
        this.name = name;
        this.properties = properties;
        this.type = type;
    }

    public get Active() {
        return this.active;
    }

    public set Active(active) {
        this.active = active;
    }

    public get DataphonePeripherals() {
        return this.dataphonePeripherals;
    }

    public set DataphonePeripherals(dataphonePeripherals) {
        this.dataphonePeripherals = dataphonePeripherals;
    }

    public get Gateway() {
        return this.gateway;
    }

    public set Gateway(gateway) {
        this.gateway = gateway;
    }

    public get Image() {
        return this.image;
    }

    public set Image(image) {
        this.image = image;
    }

    public get InternalId() {
        return this.internalId;
    }

    public set InternalId(internalId) {
        this.internalId = internalId;
    }

    public get Invoiceprefix() {
        return this.invoiceprefix;
    }

    public set Invoiceprefix(invoiceprefix) {
        this.invoiceprefix = invoiceprefix;
    }

    public get Name() {
        return this.name;
    }

    public set Name(name) {
        this.name = name;
    }

    public get Properties() {
        return this.properties;
    }

    public set Properties(properties) {
        this.properties = properties;
    }

    public get Type() {
        return this.type;
    }

    public set Type(type) {
        this.type = type;
    }
}
