import { Component, OnInit, HostListener } from '@angular/core';
import { DialogOptions } from '../dialog/dialog.model';
import { DialogRef } from '../dialog/dialog-ref';
import { CartService } from 'src/app/services/cart-services/cart.service';
import { InactivityService } from 'src/app/services/inactivity-service/inactivity.service';
import { StorageService } from 'src/app/services/storage-service/storage.service';
import { CartItemData, CartItemOrigin } from 'src/app/models/cart-item.model';

@Component({
  selector: 'app-product-dialog',
  templateUrl: './product-dialog.component.html',
  styleUrls: ['./product-dialog.component.scss']
})
export class ProductDialogComponent implements OnInit {

  selectedPreparation;
  selectedQty;
  primaryColor;

  constructor(public data: DialogOptions,
              private cartService: CartService,
              private storageService: StorageService,
              private inactivityService: InactivityService,
              public dialogRef: DialogRef) {}

  ngOnInit() {
    this.primaryColor = this.storageService.getMCatalogPrimaryColor();
  }

  @HostListener('click', ['$event'])
  public click(e) {
    // this.inactivityService.reloadInactivityTimer();
  }

  public preparationSelected(e) {
    this.selectedPreparation = e;
  }

  public qtySelected(e) {
    this.selectedQty = e;
  }

  public cancel() {
    this.dialogRef.close();
  }

  public add() {
    this.cartService.addItem(Date.now(), new CartItemData(this.data.data.catalog, this.selectedPreparation, this.data.data.product, this.selectedQty), CartItemOrigin.MANUAL);
    this.dialogRef.close();
  }
}
