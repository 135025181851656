import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
const Fingerprint2 = require('fingerprintjs2');
declare const Android;
@Injectable({
  providedIn: 'root'
})
export class FingerprintService {

  browserLang;

  constructor() { }

  public getFingerPrint() {
    return Observable.create(
      (observable) => {
        Fingerprint2.getV18(
          (components) => {
            observable.next({components});
          }
        );
      }
    );
  }

  public getAndroidFingerprint() {
    try {
      // tslint:disable-next-line:only-arrow-functions
      (function() { Android.deviceInfo(); })();
      return true;
    } catch (e) {
      console.dir(e);
      return false;
    }
  }

  public getLanguage() {
    this.browserLang = navigator.language;
    if (this.browserLang !== null) {
      switch (true) {
        case (/es/).test(this.browserLang):
          return 'es_ES';
        case (/en/).test(this.browserLang):
          return 'en_GB';
        case (/fr/).test(this.browserLang):
          return 'fr_FR';
        case (/pt/).test(this.browserLang):
          return 'pt_PT';
        case (/it/).test(this.browserLang):
          return 'it_IT';
        case (/nl/).test(this.browserLang):
          return 'nl_NL';
        default:
          return 'en';
      }
    }
  }

}
